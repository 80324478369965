import { Box, Card, Container, Typography } from '@material-ui/core';
import { experimentalStyled } from '@material-ui/core/styles';
import moment from 'moment';
import { useEffect, useState } from 'react';
import { defaultConfig } from 'src/config';
import { getUser } from 'src/helpers/auth';
import { callAPI } from 'src/utils/apiUtils';
import VersionDialog from './VersionDialog';

const FooterWrapper = experimentalStyled(Card)(
  ({ theme }) => `
        border-radius: 0;
        margin-top: ${theme.spacing(3)};
        overflow: initial;
`
);

const FooterContent = experimentalStyled(Box)(
  ({ theme }) => `
        background: ${theme.colors.alpha.white[100]};
        padding: ${theme.spacing(3, 0)};
`
);

function Footer() {
  const user = getUser()
  const [ versionModal, setVersionModal ] = useState(false)
  let clientName,arr;
  let urlhostname =  window.location.hostname;
  
    arr = urlhostname.split('.');

  if(arr.length > 0){
    let str1 = arr[0];
    clientName = str1.charAt(0).toUpperCase() + str1.slice(1);
  }

  const emptyCache  = () => {
    localStorage.removeItem('userData')
    localStorage.removeItem('isAuthenticated')
    localStorage.removeItem('accessToken')
    caches.keys().then((keyList) => Promise.all(keyList.map((key) => caches.delete(key))))
    window.location.reload();
  }

  const checkNewVersion = async () => {
    const res= await callAPI('check-version',{}, 'GET');
    const currentVersion =  res?.data?.data
    if(currentVersion?.releaseVersion !== user?.version?.releaseVersion){
      setVersionModal(true);
    } else {
      setVersionModal(false);
    }
  }

  useEffect(() => {
    checkNewVersion()
  },[])
  // useEffect(() => {
  //   if(user?.version?.releaseVersion !== defaultConfig.version){
  //     setVersionModal(true);
  //   } else{
  //     setVersionModal(false);
  //   }
  // },[user?.version?.releaseVersion])

  return (
    <FooterWrapper>
      <VersionDialog 
          isOpen={versionModal}
          handleDisagree={() => setVersionModal(false)}
          handleAgree={emptyCache}
      />
      <Container maxWidth="lg">
        <FooterContent
          display={{ xs: 'block', md: 'flex' }}
          alignItems="center"
          textAlign={{ xs: 'center', md: 'left' }}
          justifyContent="space-between"
        >
          <Box>
            <Typography variant="subtitle1" className="version_footer">
              &copy; 2021 {clientName}
              <small> All Rights Reserved | Powered by Shala Ver. <b>{user?.version?.releaseVersion} {user?.version?.releaseVersion? `| Released at ${user?.version?.releaseDatetime}` : ''}</b></small>
            </Typography>
          </Box>
          {/* <Typography sx={{ pt: { xs: 2, md: 0 } }} variant="subtitle1">
            Crafted by BloomUI.com
          </Typography> */}
        </FooterContent>
      </Container>
    </FooterWrapper>
  );
}

export default Footer;
