
import { useContext , useState} from 'react';
import { useHistory } from 'react-router-dom';
import { Scrollbars } from 'react-custom-scrollbars-2';
import { SidebarContext } from 'src/contexts/SidebarContext';
import { useTranslation } from 'react-i18next';
//import MenuIcon from '@material-ui/icons/Menu';
//import LaunchOutlinedIcon from '@material-ui/icons/LaunchOutlined';
//import ArrowLeftOutlinedIcon from '@material-ui/icons/ArrowLeftOutlined';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import {
  Box,
  Divider,
  Drawer,
  Hidden,
  IconButton
} from '@material-ui/core';

import { experimentalStyled, makeStyles } from '@material-ui/core/styles';
import SidebarMenu from './SidebarMenu';
//import useAuth from 'src/hooks/useAuth';
import { getUser } from 'src/helpers/auth';
import UserMenus from './SidebarMenu/userMenu';
import menuItems from './SidebarMenu/items';
import { prefixUrl } from 'src/helpers/common';

export const SidebarWrapper = experimentalStyled(Box)(
  ({ theme }) => `
        width: ${theme.sidebar.width};
        color: ${theme.sidebar.textColor};
        background: ${theme.sidebar.background};
        box-shadow: ${theme.sidebar.boxShadow};
        height: 100%;
        
        @media (min-width: ${theme.breakpoints.values.lg}px) {
            position: fixed;
            z-index: 10;
            border-top-right-radius: ${theme.general.borderRadius};
            border-bottom-right-radius: ${theme.general.borderRadius};
        }
`
);

export const TopSection = experimentalStyled(Box)(
  ({ theme }) => `
        display: flex;  
        gap: 5px;
        margin: ${theme.spacing(3, 4)};
`
);

export const TopSectionPubic = experimentalStyled(Box)(
  ({ theme }) => `
        display: flex;  
        gap: 5px;
        margin: ${theme.spacing(1, 8)};
`
);

export const SidebarDivider = experimentalStyled(Divider)(
  ({ theme }) => `
        background: ${theme.sidebar.dividerBg};
`
);

export const useImgStyle = makeStyles({
  root: {
    height: 40,
    'object-fit': 'contain',
    width: 150
  }
});

function Sidebar() {
  const { t }: { t: any } = useTranslation();
  const history = useHistory();
  const { sidebarToggle, toggleSidebar } = useContext(SidebarContext);
  const [openUserMenu, setOpenUserMenu] = useState(false);
  const handleUserMenu= (val)=>setOpenUserMenu(val);
 
  const closeSidebar = () => toggleSidebar();
  const classes = useImgStyle();

  // const { user } = useAuth();
  const user = getUser();

  const goToCirclesVisual = () => history.push(`${prefixUrl}/circles/visual`);

 
  return (
    <>
      <Hidden lgDown>
        <SidebarWrapper className='sidebar-main'>
          <Scrollbars autoHide>
            <TopSection>
              {/* <Button
                variant="contained"
                fullWidth
                color="primary"
                component="a"
                href="https://material-ui.com/store/items/tokyo-react-admin-dashboard"
                target="_blank"
                rel="noopener"
              >
                {t('Buy template')}
              </Button> */}
              <a>
                <img
                  src={
                    user?.client != undefined &&
                    Object.keys(user?.client).length > 0
                      ? user?.client?.logoFinal
                      : 'https://innerworks.shala.us/images/clients/innerworks_logo_5-innerworks-university.png'
                  }
                  className={classes.root}
                />
              </a>
              <IconButton
                sx={{ borderRadius: '50%' }}
                onClick={goToCirclesVisual}
              >
                <ExpandMoreIcon fontSize="small" />
              </IconButton>
            </TopSection>
            <SidebarDivider sx={{ my: 2, mx: 2 }} />
            <SidebarMenu openUserMenu={openUserMenu} />
          </Scrollbars>
          <UserMenus menuItems={menuItems} handleUserMenu={handleUserMenu}/>
        </SidebarWrapper>
      </Hidden>
      <Hidden lgUp>
        <Drawer
          anchor="left"
          open={sidebarToggle}
          onClose={closeSidebar}
          variant="temporary"
          elevation={9}
        >
          <SidebarWrapper>
            <Scrollbars autoHide>
              <TopSection>
                {/* <Button variant="contained" fullWidth color="primary">
                  {t('Buy template')}
                </Button> */}
                <a>
                  <img
                    src={
                      user?.client != undefined &&
                      Object.keys(user?.client).length > 0
                        ? user?.client?.logoFinal
                        : 'https://innerworks.shala.us/images/clients/innerworks_logo_5-innerworks-university.png'
                    }
                    className={classes.root}
                  />
                </a>
              </TopSection>
              <SidebarDivider sx={{ my: 2, mx: 2 }} />
              <SidebarMenu openUserMenu={openUserMenu}/>
            </Scrollbars>
            <UserMenus menuItems={menuItems} handleUserMenu={handleUserMenu}/>
          </SidebarWrapper>
        </Drawer>
      </Hidden>
    </>
  );
}

export default Sidebar;
