import { Suspense, Fragment, lazy } from 'react';
import { Switch, Redirect, Route } from 'react-router-dom';

import SidebarLayout from 'src/layouts/SidebarLayout';
//import DocsLayout from './layouts/DocsLayout';
import BaseLayout from 'src/layouts/BaseLayout';

//import Overview from 'src/content/overview';
import SuspenseLoader from 'src/components/SuspenseLoader';
import Guest from 'src/components/Guest';
import Authenticated from 'src/components/Authenticated';
import AccessType from 'src/components/AccessType/index';
import { prefixUrl } from 'src/helpers/common';
import PublicLayout from './layouts/SidebarLayout/Public';

type Routes = {
  exact?: boolean;
  path?: string | string[];
  guard?: any;
  layout?: any;
  component?: any;
  routes?: Routes;
}[];
//let accesstype=getUser().jobtitle.trim()==="Member"? true: false ;
export const renderRoutes = (routes: Routes = []): JSX.Element => (
  <Suspense fallback={<SuspenseLoader />}>
    <Switch>
      {routes.map((route, i) => {
        const Guard = route.guard || Fragment;
        const Layout = route.layout || Fragment;
        const Component = route.component;

        return (
          <Route
            key={i}
            path={route.path}
            exact={route.exact}
            render={(props) => (
              <Guard>
                <Layout>
                  {route.routes ? (
                    renderRoutes(route.routes)
                  ) : (
                    <Component {...props} />
                  )}
                </Layout>
              </Guard>
            )}
          />
        );
      })}
    </Switch>
  </Suspense>
);

const routes: Routes = [
  // {
  {
    exact: true,
    path: `/`, //
    layout: PublicLayout,
    routes: [
      {
        exact: true,
        path: `/`,
        component: lazy(
          () => import('src/pages/dashboards/Circles/Profile/Public')
        )
      }
    ]
  },
  {
    exact: true,
    path: `/circle/:id`, //
    layout: PublicLayout,
    routes: [
      {
        exact: true,
        path: `/circle/:id`,
        component: lazy(
          () => import('src/pages/dashboards/Circles/Profile/Public')
        )
      }
    ]
  },
  {
    exact: true,
    guard: Guest,
    path: `/enter`,
    component: lazy(() => import('src/pages/auth/Enter'))
  },
  {
    exact: true,
    guard: Guest,
    path: `/register`,
    component: lazy(() => import('src/pages/auth/Register'))
  },
  {
    exact: true,
    guard: Guest,
    path: `/login`,
    component: lazy(() => import('src/pages/auth/Login'))
  },
  {
    exact: true,
    guard: Guest,
    path: `/signup`,
    component: lazy(() => import('src/pages/auth/SignUp'))
  },
  {
    exact: true,
    guard: Guest,
    path: `${prefixUrl}/verify/:id/:type/:token`, //
    component: lazy(() => import('src/pages/auth/RecoveryPassword'))
  },
  {
    path: `${prefixUrl}/`,
    guard: Authenticated,
    layout: SidebarLayout,
    routes: [
      {
        exact: true,
        path: `${prefixUrl}/`,
        component: lazy(() => import('src/pages/dashboards/MyJourney'))
      },
      {
        exact: true,
        path: `${prefixUrl}/explorecontent`,
        component: lazy(() => import('src/pages/dashboards/Explore'))
      },
      {
        exact: true,
        path: `${prefixUrl}/library`,
        component: lazy(() => import('src/pages/dashboards/Explore'))
      },
      {
        exact: true,
        path: `${prefixUrl}/connections`,
        component: lazy(() => import('src/pages/dashboards/Connections'))
      },
      {
        exact: true,
        path: `${prefixUrl}/connection/:id`,
        component: lazy(
          () => import('src/pages/dashboards/Connections/Profile')
        )
      },
      {
        exact: true,
        path: `${prefixUrl}/myguides`,
        component: lazy(() => import('src/pages/dashboards/MyGuides'))
      },
      {
        exact: true,
        path: `${prefixUrl}/myguides/profile/:id`,
        component: lazy(
          () => import('src/pages/dashboards/MyGuides/GuideProfile')
        )
      },
      {
        exact: true,
        path: `${prefixUrl}/circles`,
        component: lazy(() => import('src/pages/dashboards/Circles'))
      },
      {
        exact: true,
        path: `${prefixUrl}/circles/profile/:id`,
        component: lazy(() => import('src/pages/dashboards/Circles/Profile'))
      },
      {
        exact: true,
        path: `${prefixUrl}/circles/visual`,
        component: lazy(() => import('src/pages/dashboards/Circles/Visual'))
      },
      {
        exact: true,
        path: `${prefixUrl}/myprofile/`,
        component: lazy(() => import('src/pages/dashboards/MyProfile'))
      },
      {
        exact: true,
        path: `${prefixUrl}/myprofile/circle/profile/:id`,
        component: lazy(() => import('src/pages/dashboards/Circles/Profile'))
      },
      // {
      //   exact: true,
      //   path: `${prefixUrl}/circles/:circleName/:innerCircleNameParam/:innerMostCircleNameParam`,
      //   component: lazy(() => import('src/pages/dashboards/Circles/InnerCircle/InnerMostCircle'))
      // },
      {
        exact: true,
        path: `${prefixUrl}/myaccount`,
        component: lazy(() => import('src/pages/dashboards/MyAccount'))
      },
      {
        exact: true,
        guard: AccessType,
        path: `${prefixUrl}/users`,
        component: lazy(() => import('src/pages/dashboards/Users'))
      }
    ]
  },
  {
    path: '*',
    layout: BaseLayout,
    routes: [
      {
        component: () => {
          let isAuthenticated = localStorage.getItem('isAuthenticated');
          if (isAuthenticated) {
            return <Redirect to={`${prefixUrl}/`} />;
          } else {
            return <Redirect to={`/login`} />;
            // <Redirect to="/login" />
          }
        }
      }
      // {
      //   exact: true,
      //   path: '/',
      //   component: Overview
      // },
      // {
      //   component: () => <Redirect to="/" />
      // }
    ]
  }
];

export default routes;
