import { createSlice } from '@reduxjs/toolkit';
import type { PayloadAction } from '@reduxjs/toolkit';
import _ from 'lodash';
import type { AppThunk } from 'src/store'
import axios from 'src/utils/axios';
import type { Event, ViewOption } from 'src/models/schedule';
//import type { Event } from 'src/models/calendar';

interface State {
    schedule: Event,
    scheduleList:Event[],
    setEventIndex:number,
    call_History:Event[],
    Todo_History:Event[],
    zoomStatus:boolean,
    showDueDatePicker:boolean,
    guideId:string,
    circleId:string,
    onlocation_event:object,
    defaultTab:string,
    tabList:object,
    viewOption:ViewOption
}

const initialState = {
  schedule: {},
  scheduleList:[],
  selectEventIndex:0,
  selectEventIndexTodo:0,
  selectEventIndexLive:0,
  call_History:[],
  Todo_History:[],
  zoomStatus:false,
  showDueDatePicker:false,
  guideId:'all',
  circleId:'all',
  onlocation_event:{},
  defaultTab:'my_schedule',
  tabListCall:{my_schedule:false, to_do:false},
  viewOption:'list',
};

const slice = createSlice({
  name: 'schedule',
  initialState,
  reducers: {
    setViewOption(state:State, action:PayloadAction<{option:ViewOption;}>){
      const {option} = action.payload;
      state.viewOption = option;
     },
    getEvents(state: State, action: PayloadAction<{ events: Event[]; }>) {
      const { events } = action.payload;
      state.scheduleList = events;
    },
    setEventIndex(state: State, action: PayloadAction<{ event: number; }>) {
      state.selectEventIndex = action.payload;
    },
    getHistory(state: State, action: PayloadAction<{ event: Event[]; }>) {
      const { event } = action.payload;
      state.call_History = event;
    },
    getTodo(state: State, action: PayloadAction<{ event: Event[]; }>) {
      const { event } = action.payload;
      state.Todo_History = event;
    },
    checkZoomStatus(state: State, action: PayloadAction<{ status: boolean; }>) {
      state.zoomStatus = action.payload;
    },
    selectEvent(state, action: PayloadAction<{ event: Event; }>) {
      const { event } = action.payload;
      //console.log('event reducer',event)
      state.schedule = event;
    },
    setDueDatePickerStatus(state: State, action: PayloadAction<{ showDueDatePicker: boolean; }>) {
      //console.log('set daue ddate',action.payload)
      state.showDueDatePicker = action.payload;
    }, 
    setGuide(state: State, action: PayloadAction<{ guideId: string; }>) {
      state.guideId = action.payload;
    },
    setCircle(state: State, action: PayloadAction<{ circleId: string; }>) {
      state.circleId = action.payload;
    },
    addEvent(state: State, action: PayloadAction<{ event: string; }>) {
      state.onlocation_event = action.payload;
    },
    setDefaultTab(state: State, action: PayloadAction<{ defaultTab: string; }>){
     let data = action.payload;
     if(Object.keys(data).length > 0){
       if(data.currentTab == 'my_schedule' && !state.tabListCall.my_schedule){
        //  console.log('my_schedule',data)
        state.tabListCall.my_schedule = true;
        state.defaultTab = data.nextTab;
       }else if(data.currentTab == 'to_do' && !state.tabListCall.to_do){
        // console.log('to_do',data)
        state.tabListCall.to_do = true;
        state.defaultTab = data.nextTab;
       }
     }
    },
    
  }
});

export const reducer = slice.reducer;

export const getEvents = (events: Event[]): AppThunk => async (dispatch) => {
  dispatch(slice.actions.getEvents({events}));
};


export const getHistory = (event: Event[]): AppThunk => async (dispatch) => {
  dispatch(slice.actions.getHistory({event}));
};

export const setEventIndex = (event: number): AppThunk => async (dispatch) => {
  //console.log('eventsiii..',event)
  dispatch(slice.actions.setEventIndex(event));
};

export const getTodo = (event: Event[]): AppThunk => async (dispatch) => {
  //console.log('eventsiii..',event)
  dispatch(slice.actions.getTodo({event}));
};


export const checkZoomStatus = (status: any): AppThunk => async (dispatch) => {
   dispatch(slice.actions.checkZoomStatus(status));
};

export const selectEvent = (event: Event): AppThunk => async (dispatch) => {
 // console.log("test")
  dispatch(slice.actions.selectEvent({ event }));
};

export const setDueDatePickerStatus = (status: any): AppThunk => async (dispatch) => {
  //console.log('dueee',status)
  dispatch(slice.actions.setDueDatePickerStatus(status));
};
export const setGuide = (guideId: any): AppThunk => async (dispatch) => {
  //console.log('guidedd id',guideId)
  dispatch(slice.actions.setGuide(guideId));
};
export const setCircle = (circleId: any): AppThunk => async (dispatch) => {
 // console.log('circle id',circleId)
  dispatch(slice.actions.setCircle(circleId));
};

export const addOnLocationEvent = (event: any): AppThunk => async (dispatch) => {
  // console.log('event sdfdsf id',event)
   dispatch(slice.actions.addEvent(event));
 };
 export const setDefaultTab = (defaultTab: any): AppThunk => async (dispatch) => {
   //console.log('defaultTab id',defaultTab)
  
   dispatch(slice.actions.setDefaultTab(defaultTab));
 };
 export const setViewOption = (option: string): AppThunk => async (dispatch) => {
  await dispatch(slice.actions.setViewOption({option:option}));
};

export default slice;
