export const auth0Config = {
  client_id: process.env.REACT_APP_AUTH0_CLIENT_ID,
  domain: process.env.REACT_APP_AUTH0_DOMAIN
};

export const firebaseConfig = {
  apiKey: process.env.REACT_APP_API_KEY,
  authDomain: process.env.REACT_APP_AUTH_DOMAIN,
  databaseURL: process.env.REACT_APP_DATABASE_URL,
  projectId: process.env.REACT_APP_PROJECT_ID,
  storageBucket: process.env.REACT_APP_STORAGE_BUCKET,
  messagingSenderId: process.env.REACT_APP_MESSAGING_SENDER_ID,
  appId: process.env.REACT_APP_ID,
  measurementId: process.env.REACT_APP_MEASUREMENT_ID
};

export const zoomSDKDefaultConfig = {
  public_key: "FjyVrQ7KTFyw1IR93GluPw", //Production
  secret_key: "cwT3mT01sl4chWFaOVnjA4ZzzMpzik9gisZI", //Production,
};

export const zoomAuthDefaultConfig = {
  public_key: "go3Ws0HIQa6AuY1P4wIZDg", //Development 
  secret_key: "7ZybvDrXXQjpPpEqHj09cDKCLWuk8Kib", //Development
  ZOOM_API_KEY: "go3Ws0HIQa6AuY1P4wIZDg",
  ZOOM_REDIRECT_URL: 'http://localhost:3000/dashboard/myaccount'//https://innerworks.shala.us/zoom-activate
  //ZOOM_REDIRECT_URL:'https://innerworks.consciousmission.com/dashboard/myaccount'
};

//console.log('process.env>>', process.env.REACT_APP_SOCKET_URL , process.env.REACT_APP_BASE_URL)

export const defaultConfig = {
  //baseAPIUrl: "https://backend.shala.us/", //Production server base api url "homepage": "http://*.shala.us/dashboard/"
  //baseAPIUrl: "https://backend.consciousmission.com/", //Development server base api url  /*"homepage": "http://*.consciousmission.com/dashboard/"*/
  //baseAPIUrl:"http://localhost:8000/",
  // baseAPIUrl:"http://3.135.29.166/backend/", //AwsProduction server  "homepage": "http://3.135.29.166/dashboard/"
  //baseAPIUrl:"https://shala.us/backend/public/", //AwsProduction server  "homepage": "http://*.shala.us/dashboard/"
  //baseAPIUrl:"https://shala.us/test/backend/public/", //AwsProduction server  "homepage": "http://3.135.29.166/test/dashboard/"

  //baseAPIUrl:"https://innerworks.consciousmission.com/backend/public/", /*"homepage": "http://*.consciousmission.com/dashboard/"*/

  baseAPIUrl: process.env.REACT_APP_BASE_URL, //"https://innerworks.consciousmission.com/backend/public/",

  //baseAPIUrl: "https://fresh-kangaroo-7.loca.lt/",
  //baseAPIUrl:"http://192.168.1.19:8087/",

  shalaUrl: "https://shala.us/",
  prefix_url: '/',
  clientId: 8,
  // hostName:'ephata.shala.us',
  hostName:'innerworks.shala.us',
  userImage_URL:"https://shala.us/backend/public/assets/images/users/",
  SOCKET_URL: process.env.REACT_APP_SOCKET_URL, //"https://socket.shala.us:4435",
  version:"2M1",
  circleContentImage_URL : 'https://innerworks.consciousmission.com/backend/public/assets/images/circles/',
};

export const spotlightrConfig = {
  videoCreateUrl: 'https://api.vooplayer.com/api/createVideo',
  videoShowUrl: 'https://convertview.cdn.spotlightr.com/watch/',
  vookey: 'c53bcd228d6d73879a567d77fa35c5368d390ecb'
}


export const googleCalendarConfig = {
  gapi: window.gapi,
  CLIENT_ID: "69665491221-9ingscq628nin3j9afj5srr5iva6a5bi.apps.googleusercontent.com",
  API_KEY: "AIzaSyDyRuG56afYCpINGZinH3-7xcZ5wuUesHo",
  DISCOVERY_DOCS: ["https://www.googleapis.com/discovery/v1/apis/calendar/v3/rest"],
  SCOPES: "https://www.googleapis.com/auth/calendar.events"
}

export const microsoftCalendarConfig = {
  TENANT_ID: "f8cdef31-a31e-4b4a-93e4-5f571e91255a",
  CLIENT_ID: "1275ed1f-967a-478b-a63d-aa2b08c84ab9",
  CLIENT_SECRET: "9409491c-023b-4b85-a695-41204676b2d6",
  SCOPES: []
}

export const gmailAuth0Config = {
  CLIENT_ID: '544567048035-8klpbkls52i21bq7b71bm6c44t54321a.apps.googleusercontent.com',
  REDIRECT_URL_SIGNUP: `https://${defaultConfig.hostName}/signup`,
  REDIRECT_URL_SIGNIN: `https://${defaultConfig.hostName}/login`,
  REDIRECT_URL_GOOGLE: 'https://shala.us/backend/public/api/get-google-access-token/',


}


export const appleAuth0Config = {
  CLIENT_ID: 'com.shalausapp',
  REDIRECT_URL_SIGNUP: 'http://localhost:3000/signup',
  REDIRECT_URL_SIGNIN: `http://${defaultConfig.hostName}/login`,
  REDIRECT_URL_APPLE: 'https://shala.us/backend/public/api/get-apple-access-token',
}
