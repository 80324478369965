import { FC, ReactNode } from 'react';
import { Redirect } from 'react-router-dom';
import PropTypes from 'prop-types';
import useAuth from 'src/hooks/useAuth';
import { isAuth } from 'src/helpers/auth';
import {prefixUrl} from 'src/helpers/common';

interface GuestProps {
  children?: ReactNode;
}

const Guest: FC<GuestProps> = ({ children }) => {
  const { isAuthenticated, setAuthData ,user} = useAuth();
  if(isAuth()){
    setAuthData();   
  }
  if (isAuthenticated) {
    if(user?.clientPrimaryCircle?.id){
      return <Redirect to={`${prefixUrl}/`} />;
    }else{
      return <Redirect to={`${prefixUrl}/`} />;
    }
    
  }

  return <>{children}</>;
};

Guest.propTypes = {
  children: PropTypes.node
};

export default Guest;
