import { useSnackbar,WithSnackbarProps, OptionsObject } from 'notistack'
import React from 'react'

let useSnackbarRef: WithSnackbarProps
export const SnackbarUtilsConfigurator: React.FC = () => {
  useSnackbarRef = useSnackbar()
  return null
}

export default {
  success(msg: string, options: OptionsObject = {}) {
    this.toast(msg, { ...options, variant: 'success',
    persist: false,
    anchorOrigin: {
      vertical: 'top',
      horizontal: 'right',
    }})
  },
  warning(msg: string, options: OptionsObject = {}) {
    this.toast(msg, { ...options, variant: 'warning',
    persist: false,
    anchorOrigin: {
      vertical: 'top',
      horizontal: 'right',
    }})
  },
  info(msg: string, options: OptionsObject = {}) {
    this.toast(msg, { ...options, variant: 'info',
    persist: false,
    anchorOrigin: {
      vertical: 'top',
      horizontal: 'right',
    }})
  },
  error(msg: string, options: OptionsObject = {}) {
    this.toast(msg, { ...options, variant: 'error',
    persist: false,
    anchorOrigin: {
      vertical: 'top',
      horizontal: 'right',
    }})
  },
  toast(msg: string, options: OptionsObject = {}) {
    useSnackbarRef.enqueueSnackbar(msg, options)
  }
}