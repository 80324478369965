import axios from 'axios';
import axiosUtil from 'src/utils/axiosUtil';
import Qs from 'qs';
import {defaultConfig} from 'src/config';

const serializerConfig = {
    arrayFormat: 'brackets',
    encode: false,
};

async function callAPI(path, params, method, data = null, options = {}, headersObj = {}) {
    const API_ROOT = defaultConfig.baseAPIUrl+'api/';
    const url = API_ROOT + path;


    const headers = {
        'Content-Type': 'application/json',
        Accept: 'application/json',
        Authorization: `Bearer ${await localStorage.getItem('accessToken')}`,
        AuthKey:await localStorage.getItem('authkey'),
        ...headersObj,
    };

    return axiosUtil({
        method,
        url,
        params,
        paramsSerializer: (paramObject) => Qs.stringify(paramObject, serializerConfig),
        data,
        headers,
        ...options,
    });
}

function callAPIWithoutAuth(path, params, method, data = null, options = {}, headersObj = {}){
    const API_ROOT = defaultConfig.baseAPIUrl+'api/';
    const url = API_ROOT + path;
    const headers = {
        'Content-Type': 'application/json',
        Accept: 'application/json',
        ...headersObj,
    };

    return axios({
        method,
        url,
        params,
        paramsSerializer: (paramObject) => Qs.stringify(paramObject, serializerConfig),
        data,
        headers,
        ...options,
    });
}

async function callAPI2(path, params, method, data = null, options = {}, headersObj = {}) {
    const API_ROOT = defaultConfig.baseAPIUrl+'api/';
    const url = API_ROOT + path;
    const headers = {
        Authorization: `Bearer ${await localStorage.getItem('accessToken')}`,
        AuthKey:await localStorage.getItem('authkey'),
        ...headersObj,
    };

    return axiosUtil({
        method,
        url,
        params,
        paramsSerializer: (paramObject) => Qs.stringify(paramObject, serializerConfig),
        data,
        headers,
        ...options,
        
    });
}

async function ThirdPartycallAPI(path, params, method, data = null, options = {}, headersObj = {}) {
    //const API_ROOT = defaultConfig.baseAPIUrl+'api/';
   // const url = API_ROOT + path;
    const url = path;
    const headers = {
        ...headersObj,
    };

    return axios({
        method,
        url,
        params,
        paramsSerializer: (paramObject) => Qs.stringify(paramObject, serializerConfig),
        data,
        headers,
        ...options,
    });
}


export { callAPI, callAPIWithoutAuth, callAPI2,ThirdPartycallAPI };