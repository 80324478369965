import {Box, Link} from '@material-ui/core';
  import { useTranslation } from 'react-i18next';
  import { experimentalStyled } from '@material-ui/core/styles';
  // import listImg from 'src/assets/images/list.png';
  // import menuImg from 'src/assets/images/menu.png';
  import PlayCircleFilledWhiteIcon from '@material-ui/icons/PlayCircleFilledWhite';
  // import ExploreIcon from '@material-ui/icons/ExploreIcon';
import ExploreIcon from '@material-ui/icons/Explore';
  import SupervisedUserCircleOutlined from '@material-ui/icons/SupervisedUserCircleOutlined';
  //import circuls from 'src/assets/images/circuls.png';
  import { useSelector } from 'src/store';
  // import {setViewOption } from 'src/slices/schedule';
import { useLocation } from 'react-router-dom';
import { prefixUrl } from 'src/helpers/common';
// import PersonOutlineTwoToneIcon from '@material-ui/icons/PersonOutlineTwoTone';
import AccountCircleIcon from '@material-ui/icons/AccountCircle';
import { ListItemIcon, Avatar} from '@material-ui/core';
import { getUser } from 'src/helpers/auth';


  
  const ListWrapper = experimentalStyled(Box)(
    ({ theme }) => `
          .MuiTouchRipple-root {
              display: none;
          }
          
          .MuiListItem-root {
              transition: ${theme.transitions.create(['color', 'fill'])};
              
              &.MuiListItem-indicators {
                  padding: ${theme.spacing(1, 2)};
              
                  .MuiListItemText-root {
                      .MuiTypography-root {
                          &:before {
                              height: 4px;
                              width: 22px;
                              opacity: 0;
                              visibility: hidden;
                              display: block;
                              position: absolute;
                              bottom: -10px;
                              transition: all .2s;
                              border-radius: ${theme.general.borderRadiusLg};
                              content: "";
                              background: ${theme.colors.primary.main};
                          }
                      }
                  }
  
                  &.active,
                  &:active,
                  &:hover {
                  
                      background: transparent;
                  
                      .MuiListItemText-root {
                          .MuiTypography-root {
                              &:before {
                                  opacity: 1;
                                  visibility: visible;
                                  bottom: 0px;
                              }
                          }
                      }
                  }
              }
          }
  `
  );
  
  function HeaderMenus() {
    const { t }: { t: any } = useTranslation();
  
   // const dispatch = useDispatch();
    const { pathname } = useLocation()

    // const {viewOption} = useSelector((state)=>{return ({defaultTab:state.schedule.defaultTab,viewOption:state.schedule.viewOption});});

    const {userData} = useSelector((state)=>{return ({userData:state.myaccount.userprofile.name});});

  const user = getUser();
   


    // const handleViewOptionChange = (option) =>{
    //     if(option){
    //       dispatch(setViewOption(option));
    //     }  
    //   }

     

      // if(pathname=== prefixUrl+'/'){
      //   return(
      //     <ListWrapper className='d-flex align-items-center top_header_menus'>
      //     <span className="me-1 play_icon_view"><Link href="#"><PlayCircleFilledWhiteIcon /></Link></span>
      //     <span className="title">{t('My Journey')}</span>
      //     <div className="view-options">
      //               {/* <span className={`${viewOption == 'list' && 'active' }`} onClick={() => handleViewOptionChange('list')} ><img src={listImg} /></span> */}
      //               {/* <span className={`${viewOption == 'hierarchy' && 'active' }`}  onClick={() => handleViewOptionChange('hierarchy')} ><img src={circuls} /></span> */}
      //               {/* <span className={`${viewOption == 'grid' && 'active' }`} onClick={() => handleViewOptionChange('grid')} ><img src={menuImg} /></span> */}
      //             </div>
      //     </ListWrapper>
      //   )
      // }
      
    return (
      <>
        <ListWrapper className='d-flex align-items-center top_header_menus'>

          {(pathname=== prefixUrl+'/')?<>  <span className="me-1 play_icon_view"><Link href="#"><PlayCircleFilledWhiteIcon /></Link></span>
        <span className="title">{t('My Journey')}</span></>:" "}

          {(pathname=== prefixUrl+'/explorecontent')?<>  <span className="me-1 play_icon_view"><Link href="#"><ExploreIcon /></Link></span>
        <span className="title">{t('Explore')}</span></>:" "}

          {(pathname=== prefixUrl+'/connections')?<>  <span className="me-1 play_icon_view"><Link href="#"><SupervisedUserCircleOutlined /></Link></span>
        <span className="title">{t('Connections')}</span></>:" "}

          {((pathname=== prefixUrl+'/myprofile')&&(userData!=undefined))?<> 
          <ListItemIcon>
              <Avatar variant="rounded" alt="Remy Sharp" src={user?.image} />
            </ListItemIcon>
        <span className="title">{t(`${userData}`)}</span></>:" "}

        {(pathname=== prefixUrl+'/myaccount')?<>  <span className="me-1 play_icon_view"><Link href="#"><AccountCircleIcon /></Link></span>
        <span className="title">{t('My Account')}</span></>:" "}

        {/* <span className="me-1 play_icon_view"><Link href="#"><PlayCircleFilledWhiteIcon /></Link></span>
        <span className="title">{t('My Journey')}</span> */}
        {/* <div className="view-options"> */}
                  {/* <span className={`${viewOption == 'list' && 'active' }`} onClick={() => handleViewOptionChange('list')} ><img src={listImg} /></span> */}
                  {/* <span className={`${viewOption == 'hierarchy' && 'active' }`}  onClick={() => handleViewOptionChange('hierarchy')} ><img src={circuls} /></span> */}
                  {/* <span className={`${viewOption == 'grid' && 'active' }`} onClick={() => handleViewOptionChange('grid')} ><img src={menuImg} /></span> */}
                {/* </div> */}
        </ListWrapper>
        
      </>
    );
  }
  
   export  default HeaderMenus;
  