export const apiUrls = {guides:'guides',
                   circles:"circles",
                   schedule:'schedule',
                   call_history:'history_contents',
                   explore:'explore',
                   getGuides:'getGuides',
                   circle:'circle/',
                   guide:'guide/',
                   updateprofiledetail:'updateprofiledetail',
                   profile:'profile/',
                   personal_data:'personal_data',
                   //scheduledetail:'scheduledetail/',
                   registerCall:'registerCall',
                   zoom_api_config:'zoom_api_config/',
                   getClientId:'clientid',
                   forgetpassword:'forgetpassword',
                   resetPassword:'password/reset',
                   passwordVerify:'password/verify/',
                   login:'login',
                   getCircles:'getCircles',
                   innercircles:'innercircles/',
                   getInstitudeCircles:'getInstitudeCircles',
                   join_circle_request:'join_circle_request',
                   cancel_circle_request:'cancel_circle_request',
                  // expire_sessions:'expire_sessions',
                  // attach_video_to_session:'attach_video_to_session',
                   add_to_do:'add_to_do',
                   remove_to_do:'remove_to_do',
                   list_to_dos:'list_to_dos',
                   marked_by_user:'done_by_user/',
                   get_content_detail:'content/get_detail/',
                   //expire_session_detail:'expire_session_detail/',
                   update_due_date:'update_due_date/',
                   circle_to_do:'circle_to_do',
                   circle_by_guide:'circle_by_guide',
                   upload_file:'upload_file/',
                   save_upload_content:'save_upload_content/',
                   update_content:'update_content/',
                   delete_content:'delete_content/',
                   guides_by_circleId:'guides_by_circleId/',
                   update_to_do:'update_to_do/',
                   get_timezone_list:'get_timezone_list',
                   save_event:'save_event',
                   circles_by_user:'circles_by_user',
                   edit_event:'edit_event/',
                   //save_google_calendar_id:'save_google_calendar_id/',
                   circle_image:'circle_image/',
                   addZoomAuth:'addZoomAuth',
                   zoomClose:'zoomClose',
                   storylineComplete:'storyline_complete',
                   approve_join_request:'approve_join_request',
                   remove_circle_member:'remove_circle_member',
                   add_member_circle:'add_member_circle',
                   usersignup:'usersignup',
                   google_signup:'google-register',
                   google_signin:'google-login',
                   ios_signup:'apple-register',
                   ios_signin:'apple-login',
                   toggle_circle_members_visiblity:"toggle_circle_members_visiblity/",
                   createZoomMeeting:"createZoomMeeting",
                   refreshZoomToken:'refreshZoomToken',
                   my_own_circles:'my_own_circles',
                   send_invite_request:"send_invite_request",
                   delete_zoom_or_onsite:"delete_event_request/",
                   get_download_file:"get_download_file/",
                   userinvite:'userinvite',
                   zoom_account_info:'zoom_account_info',
                   get_coach_circles:'get_coach_circles/',
                   delete_done_content:'delete_done_content/',
                   circles_by_parentcircle:'circles_by_parentcircle/',
                   delete_event_request:'delete_event_request/',
                   update_circle_visiblity:'update_circle_visiblity/',
                   send_emails_circle_allusers:'send_emails_circle_allusers/',
                   delete_circle : 'delete-circle/',
                   create_circle : 'create-circle',
                   register_unregister : 'event/register_unregister/',
                   update_circle_image : 'update-circle-image',
                   connections:'connections',
                   connection:'connection/',
                   follow_unfollow:'user_follow_unfollow_request',
                   verify_user_password:'verify-user-password',
                   update_circle_border_status:'update-circle-border-status',
                   change_password:'change-password',
                   update_profile_images:'update-profile-images',
                   update_profile_social_link:'update-profile-social-link',
                   getprofile:'profile',
                   contentIdDetail: 'content-id-detail',
                   update_video_count:"put/video-viewcount",
                   updateCirclePublishStatus : 'update-circle-publish-status',
                   assignCircleToInviteUser : `assign-circle-to-invite-user`,
                   update_user_biografia : `update-user-biografia`,
                 };