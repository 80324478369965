import { combineReducers } from '@reduxjs/toolkit';
import { reducer as calendarReducer } from 'src/slices/calendar';
import { reducer as projectsBoardReducer } from 'src/slices/projects_board';
import { reducer as mailboxReducer } from 'src/slices/mailbox';
import { reducer as scheduleReducer } from 'src/slices/schedule';
import { reducer as myaccountReducer } from 'src/slices/myaccount';
import { reducer as exploreReducer } from 'src/slices/explore';
import { reducer as exploreMenuCircleReducer } from 'src/slices/exploreMenuCircle';


const rootReducer = combineReducers({
  calendar: calendarReducer,
  projectsBoard: projectsBoardReducer,
  mailbox: mailboxReducer,
  schedule:scheduleReducer,
  myaccount:myaccountReducer,
  explore:exploreReducer,
  menuCircle:exploreMenuCircleReducer
});

export default rootReducer;
