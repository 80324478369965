import { createSlice } from '@reduxjs/toolkit';
import type { PayloadAction } from '@reduxjs/toolkit';
import _ from 'lodash';
import type { AppThunk } from 'src/store'
import axios from 'src/utils/axios';
import type { Event } from 'src/models/schedule';
import type { ContentLibrary, MediaLibrary } from 'src/models/explore';
//import type { Event } from 'src/models/calendar';
interface NewExplore{
  id?:number,
  type?:string
}
interface State {
    newExplore: NewExplore,
    programs:MediaLibrary[],
    mediaList?:ContentLibrary,
    isEditOpen:boolean,
    editProgram:object,
    storymatcher:object,
    isOpenStoryMatcher:boolean,
    storymatcherLink:string,
    zoomOrOnsite:string,
    storyMatcherWindow:object,
    
}

const initialState = {
    newExplore: {},
    programs:[],
    mediaList:[],
    isEditOpen:false,
    editProgram:{},
    storymatcher:{},
    isOpenStoryMatcher:false,
    storymatcherLink:'',
    zoomOrOnsite:"",
    storyMatcherWindow:{}
};

const slice = createSlice({
  name: 'explore',
  initialState,
  reducers: {
    setNewExplore(state: State, action: PayloadAction<{ data: object; }>) {
      const {data} = action.payload;
      
      state.newExplore = data;
    },
    setPrograms(state: State, action: PayloadAction<{ data:MediaLibrary[] ; }>) {
      const {data} = action.payload;
        state.programs =data;
    },
    setMediaList(state: State, action: PayloadAction<{ data:ContentLibrary; }>) {
      const {data} = action.payload;
        state.mediaList = data;
        state.programs =data?.result;
    },
    editEventInviteRequest(state: State, action: PayloadAction<{ isEditOpen:boolean; selectedEvent: object; }>) {
      return {...state,isEditOpen:action.payload.isEditOpen,editProgram:action.payload.selectedEvent};
    },
    setCloseEdit(state: State, action: PayloadAction<{ isEditOpen: boolean; }>) {
       return {...state,isEditOpen:action.payload,editProgram:{}};
     },
     setCloseNew(state: State, action: PayloadAction<{ isEditOpen: any}>) {
       return {...state,newExplore:{}};
     },
     openStoryMatcher(state: State, action: PayloadAction<{ link: any}>) {
  
      return {...state,isOpenStoryMatcher:true,storyMatcherWindow:action.payload};
    },
    closeStoryMatcher(state: State, action: PayloadAction<{ isEditOpen: any}>) {
      return {...state,isOpenStoryMatcher:false,storymatcherLink:'',storyMatcherWindow:{}};
    },

    updatezoomOrOnsite(state: State, action: PayloadAction<{ isEditOpen: any}>) {
      return {...state,selectedEvent:action.payload.isEditOpen,zoomOrOnsite:action.payload};
    },
  }
});

export const reducer = slice.reducer;


export const setNewExplore = (newExplore: NewExplore): AppThunk => async (dispatch) => {
 
  dispatch(slice.actions.setNewExplore({data:newExplore}));
};

export const setPrograms = (data: MediaLibrary[]): AppThunk => async (dispatch) => {
    dispatch(slice.actions.setPrograms({data:data}));
  };
  export const setMediaList = (data: ContentLibrary): AppThunk => async (dispatch) => {
 
    dispatch(slice.actions.setMediaList({data:data}));
  };
export const editEventInviteAction = (val: any): AppThunk => async (dispatch) => {
  dispatch(slice.actions.editEventInviteRequest(val));
};

export const setCloseEdit = (val: any): AppThunk => async (dispatch) => {
  dispatch(slice.actions.setCloseEdit(val));
};

export const setCloseNew = (val: any): AppThunk => async (dispatch) => {
  dispatch(slice.actions.setCloseNew(val));
};

export const openStoryMatcher = (val: any): AppThunk => async (dispatch) => {
  dispatch(slice.actions.openStoryMatcher(val));
};
export const closeStoryMatcher = (val: any): AppThunk => async (dispatch) => {
  dispatch(slice.actions.closeStoryMatcher(val));
};
export const updatezoomOrOnsite = (val: any): AppThunk => async (dispatch) => {
  dispatch(slice.actions.updatezoomOrOnsite(val));
};

export default slice;
