import { FC, ReactNode } from 'react';
import { Redirect } from 'react-router-dom';
import PropTypes from 'prop-types';
import useAuth from 'src/hooks/useAuth';
import { isAuth } from 'src/helpers/auth';
import {prefixUrl,matchAccessType} from 'src/helpers/common';
import { getUser } from 'src/helpers/auth';

interface AccessTypeProps {
  children?: ReactNode;
}

const AccessType: FC<AccessTypeProps> = ({ children }) => {
  const userObj=getUser();
  const user=userObj?.accessType===0 || userObj?.accessType===1 ;
  console.log("user: ", user, getUser());
  if (!user) {
    return <Redirect to={`${prefixUrl}/`} />;
   // return <Redirect to={`${prefixUrl}/explorecontent`} />;
    //return <Redirect to="/preview/dashboards/analytics" />; 
  }

  return <>{children}</>;
};

AccessType.propTypes = {
  children: PropTypes.node
};

export default AccessType;
