import App from './App';
import ReactDOM from 'react-dom';
import 'src/mocks';
import 'src/utils/chart';
import * as serviceWorkerRegistration from './serviceWorkerRegistration';

import 'nprogress/nprogress.css';
import { SidebarProvider } from './contexts/SidebarContext';
import { Provider } from 'react-redux';
import store from 'src/store';
require('dotenv').config()

ReactDOM.render(
  <Provider store={store}>
    <SidebarProvider>
      <App />
    </SidebarProvider>
  </Provider>,
  document.getElementById('root')
);

serviceWorkerRegistration.register();
