import axios from 'axios';
import {removeAuth} from 'src/helpers/auth';
//import { baseAPIUrl } from 'src/helpers/common';
import { Redirect } from 'react-router-dom';
import {defaultConfig} from 'src/config';

const axiosInt = axios.create({ 
    baseURL: defaultConfig.baseAPIUrl+'api/'
}
);

axiosInt.interceptors.response.use(
  (response) => {
    return response;
  },
  (error) =>{
      if(error?.response?.status === 401){
        removeAuth(); 
      }
    Promise.reject(
      (error.response && error.response.data) || 'There is an error!'
    )
  }
   
);

export default axiosInt;
