
const setAuth = (user, authkey) =>{
  localStorage.setItem('userData',JSON.stringify(user))
  localStorage.setItem('isAuthenticated',true)
  localStorage.setItem('authkey',authkey);
}

const removeAuth = () =>{
    localStorage.removeItem('userData')
    localStorage.removeItem('isAuthenticated')
    window.localStorage.removeItem('accessToken');
    localStorage.removeItem('authkey');
  }

  const isAuth = () =>{
     return localStorage.getItem('isAuthenticated')
  }

  const getUser = () =>{
    return JSON.parse(localStorage.getItem('userData'));
  }

  const setClientId = (clientId, clientPlatformId) =>{
     localStorage.setItem('clientId',clientId);
     localStorage.setItem('clientPlatformId',clientPlatformId);
  }

  const getClientId = () =>{
      return localStorage.getItem('clientId');
  }

  const authToken = () =>{
      const accessToken = window.localStorage.getItem('accessToken');
      const authkey = localStorage.getItem('authkey');
      return {
        accessToken,
        authkey
      }
  }

  export { setAuth, removeAuth, isAuth, getUser, setClientId, getClientId,authToken }