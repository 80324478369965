import { ListSubheader, List } from '@material-ui/core';
import { useLocation, matchPath } from 'react-router-dom';
import SidebarMenuItem from './item';
import menuItems, { MenuItem } from './items';
import { experimentalStyled } from '@material-ui/core/styles';
import { useTranslation } from 'react-i18next';
import { prefixUrl } from 'src/helpers/common';
import { getUser } from 'src/helpers/auth';
export const MenuWrapper = experimentalStyled(List)(
  ({ theme }) => `
    margin-bottom: ${theme.spacing(1)};

    .MuiListSubheader-root {
      text-transform: uppercase;
      font-size: ${theme.typography.pxToRem(13)};
      color: ${theme.sidebar.menuItemHeadingColor};
      padding: ${theme.spacing(0.8, 2)};
      line-height: 1.4;
    }
`
);

const SubMenuWrapper = experimentalStyled(List)(
  ({ theme }) => `
    &.MuiList-root {

      .MuiListItem-root {
        padding: 2px;
        padding-left: ${theme.spacing(2)};
        padding-right: ${theme.spacing(2)};

        .MuiButton-label {
          display: flex;
          position: relative;

          .MuiBadge-root {
            position: absolute;
            right: ${theme.spacing(2)};

            .MuiBadge-standard {
              background: ${theme.colors.primary.main};
              font-size: ${theme.typography.pxToRem(12)};
              color: ${theme.palette.primary.contrastText};
            }
          }
        }
    
        .MuiButton-root {
          display: flex;
          color: ${theme.sidebar.menuItemColor};
          background-color: ${theme.sidebar.menuItemBg};
          width: 100%;
          justify-content: flex-start;
          font-size: ${theme.typography.pxToRem(14)};
          padding-top: ${theme.spacing(0.8)};
          padding-bottom: ${theme.spacing(0.8)};
    
          .MuiButton-startIcon,
          .MuiButton-endIcon {
            transition: ${theme.transitions.create(['color'])};

            .MuiSvgIcon-root {
              font-size: inherit;
              transition: none;
            }
          }

          .MuiButton-startIcon {
            font-size: ${theme.typography.pxToRem(26)};
            margin-right: ${theme.spacing(1.5)};
            color: ${theme.sidebar.menuItemIconColor};
          }
          
          .MuiButton-endIcon {
            margin-left: auto;
            font-size: ${theme.typography.pxToRem(22)};
          }

          &.Mui-active,
          &:hover {
            background-color: ${theme.sidebar.menuItemBgActive};
            color: ${theme.sidebar.menuItemColorActive};

            .MuiButton-startIcon,
            .MuiButton-endIcon {
                color: ${theme.sidebar.menuItemIconColorActive};
            }
          }
        }

        &.Mui-children {
          flex-direction: column;
          line-height: 1;
        }

        .MuiCollapse-root {
          width: 100%;

          .MuiList-root {
            padding: ${theme.spacing(1, 0)};
          }

          .MuiListItem-root {
            padding: 1px;
            padding-left: ${theme.spacing(0)};
            padding-right: ${theme.spacing(0)};

            .MuiButton-root {
              font-size: ${theme.typography.pxToRem(13)};
              padding: ${theme.spacing(0.5, 2, 0.5, 6.5)};

              &.Mui-active,
              &:hover {
                background-color: ${theme.sidebar.menuItemBg};
              }
            }
          }
        }
      }
    }
`
);

function renderSidebarMenuItems({
  items,
  path
}: {
  items: MenuItem[];
  path: string;
}) {
  return (
    <SubMenuWrapper>
      {items.reduce((ev, item) => reduceChildRoutes({ ev, item, path }), [])}
    </SubMenuWrapper>
  );
}

function reduceChildRoutes({
  ev,
  path,
  item
}: {
  ev: any[];
  path: string;
  item: MenuItem;
}) {
  const key = item.name;
  // console.log("items>>>>>>", item)
  // console.log("item.items>>>>>>", item.items)


  const user = getUser();

  if (item.items) {
    const open = matchPath(path, {
      path: (prefixUrl + item.link),
      exact: false
    });
    ev.push(
      <SidebarMenuItem
        key={key}
        open={Boolean(open)}
        name={item.name}
        icon={item.icon}
        badge={item.badge}
        iscomponent={item.iscomponent}
      >
        {renderSidebarMenuItems({
          path,
          items: item.items
        })}
      </SidebarMenuItem>
    );
  } else {
    (!item.role || item.role.includes(user.accessType)) && ev.push(
      <SidebarMenuItem
        key={key}
        name={item.name}
        link={prefixUrl + item.link}
        badge={item.badge}
        icon={item.icon}
        iscomponent={item.iscomponent}
      />
    );
  }

  return ev;
}

function SidebarMenu({openUserMenu = false}) {
  const location = useLocation();
  const { t }: { t: any } = useTranslation();
  const user = getUser();
  return (
    <>
      <div className={`scroll-sidebar-custom ${!openUserMenu && 'user_menu_hide'}`}>
        {menuItems.map((section) => {

          if (section.heading !== 'Member Dashboard') {
            return (
              <>
                <MenuWrapper
                  key={section.heading == 'Member Dashboard' ? user?.name : section.heading}
                  subheader={
                    <ListSubheader disableSticky>{t(section.heading == 'Member Dashboard' ? user?.name : section.heading)}</ListSubheader>
                  }
                >
                  {renderSidebarMenuItems({
                    items: section.items,
                    path: location.pathname
                  })}
                </MenuWrapper>
              </>
            );
          }
        })}
      </div>
     
    </>
  );
}

export default SidebarMenu;



