import { FC, ReactNode, createContext, useEffect, useReducer } from 'react';
import jwtDecode from 'jwt-decode';
import { User,Errors } from 'src/models/user';
import SuspenseLoader from 'src/components/SuspenseLoader';
import axios from 'src/utils/axios';
import { callAPIWithoutAuth } from 'src/utils/apiUtils'
import { setAuth, removeAuth } from 'src/helpers/auth';
import { Redirect, useHistory } from "react-router-dom";
import { apiUrls } from 'src/utils/apiUrls';


interface AuthState {
  isInitialised: boolean;
  isAuthenticated: boolean;
  user: User | null;
  errors: Errors |null;
}

interface AuthContextValue extends AuthState {
  method: 'JWT';
  googleSignUp: (token: string) => Promise<void>;
  login: (email: string, password: string) => Promise<void>;
  logout: () => void;
  register: (email: string, name: string, password: string) => Promise<void>;
  setAuthData:() => void;
}

interface AuthProviderProps {
  children: ReactNode;
}

type InitialiseAction = {
  type: 'INITIALISE';
  payload: {
    isAuthenticated: boolean;
    user: User | null;
  };
};

type LoginAction = {
  type: 'LOGIN';
  payload: {
    user: User;
    errors:Errors;
  };
};

type SetAuthAction = {
  type: 'SETAUTH';
  payload: {
    user: User;
  };
};

type LogoutAction = {
  type: 'LOGOUT';
};

type RegisterAction = {
  type: 'REGISTER';
  payload: {
    user: User;
  };
};

type Action = InitialiseAction | LoginAction | LogoutAction | RegisterAction | SetAuthAction;

const initialAuthState: AuthState = {
  isAuthenticated: false,
  isInitialised: false,
  user: null,
  errors:null
};

const isValidToken = (accessToken: string): boolean => {
  if (!accessToken) {
    return false;
  }

  const decoded: any = jwtDecode(accessToken);
  const currentTime = Date.now() / 1000;

  return decoded.exp > currentTime;
};

const setSession = (accessToken: string | null): void => {
  if (accessToken) {
    localStorage.setItem('accessToken', accessToken);
    axios.defaults.headers.common.Authorization = `Bearer ${accessToken}`;
  } else {
    localStorage.removeItem('accessToken');
    delete axios.defaults.headers.common.Authorization;
  }
};

const reducer = (state: AuthState, action: Action): AuthState => {
  switch (action.type) {
    case 'INITIALISE': {
      const { isAuthenticated, user } = action.payload;

      return {
        ...state,
        isAuthenticated,
        isInitialised: true,
        user
      };
    }
    case 'LOGIN': {
      const { user,errors } = action.payload;
      if(errors != null && Object.keys(errors).length > 0){ 
        return {
          ...state,
          isAuthenticated: false,
          errors
        };
      }else{
        return {
          ...state,
          isAuthenticated: true,
          user
        };
      }
    }
    case 'SETAUTH': {
      const { user } = action.payload;

      return {
        ...state,
        isAuthenticated: true,
        user
      };
    }
    case 'LOGOUT': {
      return {
        ...state,
        isAuthenticated: false,
        user: null
      };
    }
    case 'REGISTER': {
      const { user } = action.payload;

      return {
        ...state,
        isAuthenticated: true,
        user
      };
    }
    default: {
      return { ...state };
    }
  }
};


const AuthContext = createContext<AuthContextValue>({
  ...initialAuthState,
  method: 'JWT',
  login: () => Promise.resolve(),
  googleSignUp: () => Promise.resolve(),
  logout: () => {},
  register: () => Promise.resolve(),
  setAuthData: () =>Promise.resolve(),
});

export const AuthProvider: FC<AuthProviderProps> = ({ children }) => {
  const [state, dispatch] = useReducer(reducer, initialAuthState);
 const history= useHistory()
  const login = async (email: string, password: string) => {
     try{
      const clientId = localStorage.getItem('clientId');  
      const response = await callAPIWithoutAuth(apiUrls.login,{},'POST',{ email:email, pass:password,clientId:clientId });
      const { auth, user, authkey, allowed } = response.data;
          localStorage.setItem('allowed',allowed);
          if(allowed != 1){
            return(<Redirect to="/login" />);
          }else{
            setSession(auth.access_token);
            setAuth(user,authkey);
            dispatch({
              type: 'LOGIN',
              payload: {
                user,
                errors:null
              }
            });
          }  

     }catch(err){
        //console.log('err',err)
       // console.error('errors',err.response.data.errors);
        if(err != undefined && Object.keys(err).length > 0){
          dispatch({
            type: 'LOGIN',
            payload: {
              user:null,
              errors:err.response.data.errors
            }
          });
        }
     }
  };

  const logout = () => {
    setSession(null);
    removeAuth();
    dispatch({ type: 'LOGOUT' });
  };

  const googleSignUp = async (token: string, url:string) => {
    try{
      console.log('21');
      
      const clientId = localStorage.getItem('clientId');  
      console.log("clientId: ", clientId)
      const response = await callAPIWithoutAuth(url,{},'POST',{ token, clientId:clientId });
      console.log("response.data googleSignUp: ", response.data)
      const { auth, user, authkey, allowed } = response.data;
      localStorage.setItem('allowed',allowed);
      if(allowed != 1){
        return(<Redirect to="/login" />);
      }else{
        setSession(auth.access_token);
        setAuth(user,authkey);
        dispatch({
          type: 'LOGIN',
          payload: {
            user,
            errors:null
          }
        });
      }  

  } catch(err){
    console.log('err',err.response.data)
    console.log('googleSignUp errors',err.response.data.errors);
    if(err != undefined && Object.keys(err).length > 0){
      if (err?.response?.data?.messagetype===1){
        history.push('/login');
      }
      
      dispatch({
          type: 'LOGIN',
          payload: {
            user:null,
            errors: err.response.data.errors
          }
        });
        
        
      }
     
      // dispatch({
      //   type: 'LOGIN',
      //   payload: {
      //     user:null,
      //     errors:err.response.data.errors
      //   }
      // });
    
  }

  };



  const register = async (email: string, name: string, password: string) => {
    const response = await axios.post<{ accessToken: string; user: User }>(
      '/api/account/register',
      {
        email,
        name,
        password
      }
    );
    const { accessToken, user } = response.data;

    window.localStorage.setItem('accessToken', accessToken);

    dispatch({
      type: 'REGISTER',
      payload: {
        user
      }
    });
  };

  const setAuthData = () =>{
    let user = JSON.parse(localStorage.getItem('userData'));

    dispatch({
      type: 'SETAUTH',
      payload: {
        user
      }
    });
  }

  useEffect(() => {
    const initialise = async () => {
      try {
        const accessToken = window.localStorage.getItem('accessToken');
       
        if (accessToken && isValidToken(accessToken)) {
          setSession(accessToken);
          // const response = await axios.get<{ user: User }>(
          //   '/api/account/personal'
          // );
          // const { user } = response.data;
          const user = JSON.parse(localStorage.getItem('userData'));
          dispatch({
            type: 'INITIALISE',
            payload: {
              isAuthenticated: true,
              user
            }
          });
        } else {
          dispatch({
            type: 'INITIALISE',
            payload: {
              isAuthenticated: false,
              user: null
            }
          });
        }
      } catch (err) {
        console.error(err);
        dispatch({
          type: 'INITIALISE',
          payload: {
            isAuthenticated: false,
            user: null
          }
        });
      }
    };

    initialise();
  }, []);

  if (!state.isInitialised) {
    return <SuspenseLoader />;
  }

  return (
    <AuthContext.Provider
      value={{
        ...state,
        method: 'JWT',
        login,
        logout,
        register,
        googleSignUp,
        setAuthData
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};

export default AuthContext;
