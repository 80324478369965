import { useState } from 'react';
import { Dialog, DialogContent, DialogContentText, DialogTitle, Button, DialogActions  } from '@material-ui/core';

const VersionDialog = ({isOpen, handleAgree, handleDisagree}) => {

    return (
        <Dialog
                open={isOpen}
                onClose={handleDisagree}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
            <DialogTitle id="alert-dialog-title">{"New version released"}</DialogTitle>
            <DialogContent>
                <DialogContentText id="alert-dialog-description">
                    Please refresh browser to access latest version of the platform
                </DialogContentText>
            </DialogContent>
            <DialogActions>
                <Button onClick={handleDisagree} color="primary">
                    Cancel
                </Button>
                <Button onClick={handleAgree} color="primary" autoFocus>
                    Refresh
                </Button>
            </DialogActions>
        </Dialog>

    );

}
export default VersionDialog