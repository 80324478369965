import { useContext } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { Box, Button, Hidden, IconButton, Tooltip } from '@material-ui/core';
import { NavLink as RouterLink } from 'react-router-dom';
import { experimentalStyled } from '@material-ui/core/styles';
import MenuTwoToneIcon from '@material-ui/icons/MenuTwoTone';
import { SidebarContext } from 'src/contexts/SidebarContext';
import CloseTwoToneIcon from '@material-ui/icons/CloseTwoTone';
import HeaderMenus from './Menus';
import HeaderButtons from './Buttons';
import HeaderUserbox from './Userbox';
import { prefixUrl } from 'src/helpers/common';
import { getUser } from 'src/helpers/auth';

const HeaderWrapper = experimentalStyled(Box)(
  ({ theme }) => `
        height: ${theme.header.height};
        color: ${theme.header.textColor};
        padding: ${theme.spacing(0, 2)};
        right: 0;
        z-index: 5;
        background-color: ${theme.header.background};
        box-shadow: ${theme.header.boxShadow};
        position: fixed;
        justify-content: space-between;
        width: 100%;
        @media (min-width: ${theme.breakpoints.values.lg}px) {
            left: ${theme.sidebar.width};
            width: auto;
        }
`
);

function Header({ publicPortal = false }) {
  const user = getUser();
  const history = useHistory();
  const { pathname } = useLocation();
  const { sidebarToggle, toggleSidebar } = useContext(SidebarContext);

  const onOpenNetworkView = () => history.push(`${prefixUrl}/circles/visual`);

  const onExitNetworkView = () => history.push(`${prefixUrl}`);

  // TODO: add logic
  const onSwitchPlatform = () => {};

  return (
    <HeaderWrapper display="flex" alignItems="center">
      <Box display="flex" alignItems="center">
           <Hidden mdDown>
            <HeaderMenus />
          </Hidden>
        {pathname === prefixUrl + '/circles/' && (
          <Button variant="contained" onClick={onOpenNetworkView}>
            Open Network View
          </Button>
        )}
        {pathname === prefixUrl + '/circles/visual' && (
          <Box display="flex" gap="15px">
            <Button variant="contained" onClick={onSwitchPlatform}>
              Switch Platform
            </Button>
            <Button variant="contained" onClick={onExitNetworkView}>
              Exit Network View
            </Button>
          </Box>
        )}
      </Box>


      {user ? (
        <Box display="flex" alignItems="center">
          <HeaderButtons />
          {publicPortal && <HeaderUserbox />}
          <Hidden lgUp>
            <Tooltip arrow title="Search">
              <IconButton color="primary" onClick={toggleSidebar}>
                {!sidebarToggle ? <MenuTwoToneIcon /> : <CloseTwoToneIcon />}
              </IconButton>
            </Tooltip>
          </Hidden>

        </Box>
      ) : (
        <Box component={'div'}>
          <Box component={'span'} className="new_auth_area">
            <RouterLink to="/login">Login</RouterLink>
            <RouterLink to="/signup" className="signup">
              Sign up
            </RouterLink>
          </Box>
        </Box>
      )}
    </HeaderWrapper>
  );
}

export default Header;
