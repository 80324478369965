import moment from 'moment';

const prefixUrl = (window.location.pathname.slice(1).split('/')[1] == 'test') ? '/dashboard/test' : '/dashboard';
const checkTimeDiffInMin = (date1) => {
        const dt1 = new Date(date1);
        const dt2 = new Date();

        var difftm = (dt1.getTime() - dt2.getTime()) / 1000;
        difftm /= (60);
        return Math.round(difftm);
    }
    // const diff_hours = (dt2, dt1)=>{
    //     var diff =(dt2.getTime() - dt1.getTime()) / 1000;
    //     diff /= (60 * 60);
    //     return Math.abs(Math.round(diff));
    // }
const diff_mins = (dt2, dt1) => {
    var diff = (dt2.getTime() - dt1.getTime()) / 1000;
    diff /= (60);

    return Math.abs(Math.round(diff));
}
const getDateDiffTime = (date1) => {
    if (checkingForValidDate(date1)) {
        let str = '';
        let newdate = date1.replace(/-/g, "/");
        const dt11 = new Date(newdate);
        const dt22 = new Date();
        const days = Math.floor(diff_mins(dt11, dt22) / (24 * 60));
        const hours = Math.floor((diff_mins(dt11, dt22) / 60) % 24);
        const minutes = Math.floor((diff_mins(dt11, dt22)) % 60);
        if (days != 0) {
            str += days + ' days, ';
        }
        if (hours != 0) {
            str += hours + ' hours and ';
        }

        str += minutes + ' min ';

        return str;
    } else {
        return "";
    }
}

const getDateDiffTimeInMin = (date1) => {
    const dt11 = new Date(date1);
    const dt22 = new Date();
    return Math.round(diff_mins(dt11, dt22));
}
const getDiffFormat = (mins) => {
    const hrs = (mins / 60);
    return Math.floor(hrs / (24)) + " days, " + Math.floor(hrs % 24) + ' hours and ' + Math.floor((hrs * 60) % 60) + ' min';
}
const checkingForValidDate = (date) => {
    const regex = /^(19|20)\d\d[- /.](0[1-9]|1[012])[- /.](0[1-9]|[12][0-9]|3[01]) (20|21|22|23|[0-1]?\d{1}):([0-5]?\d{1}):([0-5]?\d{1})$/;
    if (date != '0000-00-00 00:00:00' && regex.test(date)) {
        return true;
    }
    return false;
}

const OWNER_ACCESS_TYPE = 0;
const ADMIN_ACCESS_TYPE = 1;
const GUIDE_ACCESS_TYPE = 2;
const MEMBER_ACCESS_TYPE = 3;

const matchAccessType = (accessType) => {
    if ([OWNER_ACCESS_TYPE, ADMIN_ACCESS_TYPE, MEMBER_ACCESS_TYPE].indexOf(accessType) > -1) {
        return true
    } else {
        return false
    }
}

const matchAccessTypeForCircles = (accessType) => {
    if ([OWNER_ACCESS_TYPE, ADMIN_ACCESS_TYPE, GUIDE_ACCESS_TYPE, MEMBER_ACCESS_TYPE].indexOf(accessType) > -1) {
        return true
    } else {
        return false
    }
}


const matchAccessTypeUnregister = (accessType) => {
    if ([0, 1, 2, 3].indexOf(accessType) > -1) {
        return true
    } else {
        return false
    }
}



const cheakOwnerAdminAccessTypee = (accessType) => {
    let access_type = parseInt(accessType);
    if ([0, 1].indexOf(access_type) > -1) {
        return true
    } else {
        return false
    }
}

const getDateIntoStringFormatMDY = (datestring, withTime = false) => withTime ? moment(datestring).format('MMM DD, YYYY HH:mm:ss') : moment(datestring).format('MMM, DD, YYYY');

const getTextEllipse = (text, length = 50) => {
    if (text.length > length)
        return `${text.substring(0, length)}...`;
    return text;
}

const getHeaderAuthToken = async() => ({
    Authorization: `${localStorage.getItem('accessToken')}`,
    AuthKey: await localStorage.getItem('authkey'),
})


const pageViewConst = {
    CONTENT_NOT_SHOW: 0,
    CONTENT_HALF_COLUMN: 1,
    CONTENT_FULL_COLUMN: 2,
}

const contentType = {
    FORMATTED_TEXT: 0,
    IMAGE: 7,
}

const resizeColumn = {
    0.5: '50%',
    0.33: '33%',
    0.67: '67%'
}

const isPublicPage=() => {
    return (window.location.href.split('/')).includes('public')
}


export { getDateDiffTime, getDateDiffTimeInMin, prefixUrl, checkingForValidDate, getDiffFormat, checkTimeDiffInMin, getDateIntoStringFormatMDY, matchAccessType, cheakOwnerAdminAccessTypee, matchAccessTypeUnregister, getTextEllipse, matchAccessTypeForCircles, getHeaderAuthToken, pageViewConst, contentType, resizeColumn, isPublicPage,OWNER_ACCESS_TYPE,ADMIN_ACCESS_TYPE,GUIDE_ACCESS_TYPE,MEMBER_ACCESS_TYPE, }