export const USER_ACCESS_TYPE = [
    'Owner',
    'Admin',
    'Member',
    'Guide',
    'Request',
    'Remove'
]; 

export const CONTENT_FORMAT_OPTIONS = [
    {label:'Select format',value:'select'},
    {label:'Video',value:'Video'},
    {label:'Audio',value:'Audio'},
    {label:'PDF',value:'Article'},
    {label:'Video Link',value:'Video_Link'},
    {label:'StoryMatcher',value:'StoryMatcher'},
    {label:'Download',value:'Download'},
    {label:'Zoom',value:'Zoom'},
    {label:'Onsite',value:'Onsite'}
  ];
  

  export const CONTENT_FORMAT_FILTER_OPTIONS = [
    {label:'All format',value:'all'},
    {label:'Onsite',value:'onlocation'},
    {label:'Zoom',value:'live'},
    {label:'Video',value:'video'},
    {label:'Audio',value:'audio'},
    {label:'PDF',value:'article'},
    {label:'Video Link',value:'video_link'},
    {label:'StoryMatcher',value:'storymatcher'},
    {label:'Download',value:'downloadcontent'},
   
   
  ];

  export const CONTENT_STATUS_OPTIONS = [
    {label:'All status',value:'all'},
    {label:'Live',value:'live'},
    {label:'To-do',value:'todo'},
    {label:'Done',value:'done'},
    {label:'None',value:'none'},
  ]