import { useRef, useState } from 'react';
import useAuth from 'src/hooks/useAuth';
import { NavLink } from 'react-router-dom';
import { useHistory } from 'react-router-dom';
import { prefixUrl } from 'src/helpers/common';
import LoginIcon from '@material-ui/icons/Login';
import PersonIcon from '@material-ui/icons/Person';
import {Link} from '@material-ui/core';
import PersonOutlineTwoToneIcon from '@material-ui/icons/PersonOutlineTwoTone';

import {
  Avatar,
  Box,
  Button,
  Divider,
  Hidden,
  lighten,
  List,
  ListItem,
  ListItemText,
  Popover,
  Typography
} from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import InboxTwoToneIcon from '@material-ui/icons/InboxTwoTone';
import { experimentalStyled } from '@material-ui/core/styles';
import ExpandMoreTwoToneIcon from '@material-ui/icons/ExpandMoreTwoTone';
import AccountBoxTwoToneIcon from '@material-ui/icons/AccountBoxTwoTone';
import LockOpenTwoToneIcon from '@material-ui/icons/LockOpenTwoTone';
import AccountTreeTwoToneIcon from '@material-ui/icons/AccountTreeTwoTone';
import { useDispatch, useSelector } from 'src/store';
import { getUser } from 'src/helpers/auth';
import { USER_ACCESS_TYPE } from 'src/helpers/Constant/constant';

const UserBoxButton = experimentalStyled(Button)(
  ({ theme }) => `
        padding-left: ${theme.spacing(1)};
        padding-right: ${theme.spacing(1)};
`
);

const MenuUserBox = experimentalStyled(Box)(
  ({ theme }) => `
        background: ${theme.colors.alpha.black[5]};
        padding: ${theme.spacing(2)};
`
);

const UserBoxText = experimentalStyled(Box)(
  ({ theme }) => `
        text-align: left;
        padding-left: ${theme.spacing(1)};
`
);

const UserBoxLabel = experimentalStyled(Typography)(
  ({ theme }) => `
        font-weight: ${theme.typography.fontWeightBold};
        color: ${theme.palette.secondary.main};
        display: block;
`
);

const UserBoxDescription = experimentalStyled(Typography)(
  ({ theme }) => `
        color: ${lighten(theme.palette.secondary.main, 0.5)}
`
);

function HeaderUserbox() {
  const { t }: { t: any } = useTranslation();

  const history = useHistory();

  const {  logout } = useAuth();
 const user = getUser();
  // const user =  {avatar: "/static/images/avatars/3.jpg",email: "demo@example.com",id: "1", jobtitle: "Yoga Instructor",location: "San Francisco, Romania",name: "Randy Smith",posts: "27",role: "admin"};
  //console.log(user);
  const ref = useRef<any>(null);
  const [isOpen, setOpen] = useState<boolean>(false);

  const handleOpen = (): void => {
    setOpen(true);
  };

  const handleClose = (): void => {
    setOpen(false);
  };

  const handleLogout = async (): Promise<void> => {
    try {
      handleClose();
      await logout();

      history.push(`/login`);
    } catch (err) {
      console.error(err);
    }
  };
  const { image } = useSelector((state) => {
    return ({ image: state.myaccount.userprofile });
  });
  return (
    <>
      <UserBoxButton color="secondary" ref={ref} onClick={handleOpen}>
        

        {image?.image!=undefined ? <Avatar variant="rounded" alt={image?.name} src={(image?.image) ? image?.image : process.env.PUBLIC_URL + 'static/images/avatars/3.jpg'} />:<Avatar variant="rounded" alt={user?.name} src={(user?.image) ? (user?.image) : process.env.PUBLIC_URL + 'static/images/avatars/3.jpg'} />}
        <Hidden mdDown>
          <UserBoxText>
         
            {image?.image==undefined ? <UserBoxLabel variant="body1">{user?.firstName} {user?.lastName}</UserBoxLabel>: <UserBoxLabel variant="body1">{image?.firstName} {image?.lastName}</UserBoxLabel>}
            <UserBoxDescription variant="body2">
              {USER_ACCESS_TYPE[user?.accessType]}
            </UserBoxDescription>
          </UserBoxText>
        </Hidden>
        <Hidden smDown>
          <ExpandMoreTwoToneIcon sx={{ ml: 1 }} />
        </Hidden>
      </UserBoxButton>
      <Popover
        anchorEl={ref.current}
        onClose={handleClose}
        open={isOpen}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'right'
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right'
        }}
      >
        <MenuUserBox sx={{ minWidth: 210 }} display="flex">
          <Avatar variant="rounded" alt={user?.name} src={(user?.image) ? user?.image : process.env.PUBLIC_URL + 'static/images/avatars/3.jpg'} />
          <UserBoxText>
          {image?.image==undefined ? <UserBoxLabel variant="body1">{user?.firstName} {user?.lastName}</UserBoxLabel>: <UserBoxLabel variant="body1">{image?.firstName} {image?.lastName}</UserBoxLabel>}
            <UserBoxDescription variant="body2">
              {USER_ACCESS_TYPE[user?.accessType]}
            </UserBoxDescription>
          </UserBoxText>
        </MenuUserBox>
        <Divider sx={{ mb: 0 }} />
        <List sx={{ p: 1 }} component="nav">
          <ListItem button to="/dashboard/myaccount" component={NavLink}>
            <PersonOutlineTwoToneIcon fontSize="small" />
            <ListItemText primary={t('My Account')} />
          </ListItem>
          <ListItem button onClick={handleLogout} component={Link}>
          <LoginIcon fontSize="small" sx={{marginRight:1}}  />
            <ListItemText primary={t('Sign Out')} />
          </ListItem>
          
           {/*
           <ListItem button to="/preview/management/user/1" component={NavLink}>
            <AccountBoxTwoToneIcon fontSize="small" />
            <ListItemText primary={t('Profile')} />
          </ListItem>
           <ListItem
            button
            to="/preview/applications/mailbox/inbox"
            component={NavLink}
          >
            <InboxTwoToneIcon fontSize="small" />
            <ListItemText primary={t('Inbox')} />
          </ListItem>
          <ListItem
            button
            to="/preview/applications/projects-board"
            component={NavLink}
          >
            <AccountTreeTwoToneIcon fontSize="small" />
            <ListItemText primary={t('Projects')} />
          </ListItem> */}
        </List>
        {/* <Divider />
        <Box sx={{ m: 1 }}>
          <Button color="primary" fullWidth onClick={handleLogout}>
            <LockOpenTwoToneIcon sx={{ mr: 1 }} />
            {t('Sign out')}
          </Button>
        </Box> */}
      </Popover>
    </>
  );
}

export default HeaderUserbox;
