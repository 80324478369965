import { createSlice } from '@reduxjs/toolkit';
import type { PayloadAction } from '@reduxjs/toolkit';
import _ from 'lodash';
import type { AppThunk } from 'src/store'

interface ExploreMenuCircle {
  circle: object,
  }

interface ExploreMenuitemState {
  menucircles: ExploreMenuCircle,
  menucirclesPublic: ExploreMenuCircle,
}

const initialState = {
  menucircles: {},
  menucirclesPublic: {}
};

const slice = createSlice({
  name: 'exploreMenuCircle',
  initialState,
  reducers: {
    setexploreMenuCircle(state: ExploreMenuitemState, action: PayloadAction<{ menucircles: ExploreMenuCircle; }>) {
      const { menucircles } = action.payload;
      state.menucircles = {...state.menucircles, ...menucircles};
    },
    setexploreMenuCirclePublic(state: ExploreMenuitemState, action: PayloadAction<{ menucirclesPublic: ExploreMenuCircle; }>) {
      const { menucirclesPublic } = action.payload;
      state.menucirclesPublic = {...state.menucirclesPublic, ...menucirclesPublic};
    } 
  }
});

export const reducer = slice.reducer;

export const getExploreCircleMenuItemPublic = (data: any): AppThunk => async (dispatch) => {
  dispatch(slice.actions.setexploreMenuCirclePublic({menucirclesPublic:data}));
};

export const getExploreCircleMenuItem = (data: any): AppThunk => async (dispatch) => {
    dispatch(slice.actions.setexploreMenuCircle({menucircles:data}));
  };

export default slice;
