import { FC, ReactNode, useState, useContext } from 'react';
import { NavLink as RouterLink } from 'react-router-dom';
import clsx from 'clsx';
import { SidebarContext } from 'src/contexts/SidebarContext';

import PropTypes from 'prop-types';
import { Button, Badge, Collapse, ListItem } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import ExpandLessTwoToneIcon from '@material-ui/icons/ExpandLessTwoTone';
import ExpandMoreTwoToneIcon from '@material-ui/icons/ExpandMoreTwoTone';
import { getUser } from 'src/helpers/auth';
import { prefixUrl,matchAccessType } from 'src/helpers/common';
import ChevronRightIcon from '@material-ui/icons/ChevronRight'
import ExpolerMenuItem from '../../../../components/ExpolerMenuItem/index';


interface SidebarMenuItemProps {
  children?: ReactNode;
  link?: string;
  icon?: any;
  badge?: string;
  open?: boolean;
  name: string;
  component?: ReactNode;
  iscomponent?: boolean;
}

const SidebarMenuItem: FC<SidebarMenuItemProps> = ({
  children,
  link,
  icon: Icon,
  badge,
  open: openParent,
  name,
  component: component,
  iscomponent: iscomponent,
  ...rest
}) => {
  const [menuToggle, setMenuToggle] = useState<boolean>(openParent);
  const { t }: { t: any } = useTranslation();
  const { toggleSidebar } = useContext(SidebarContext);
  const user = getUser ();

  // console.log("children , >>>>>:", badge ,name, children);
  // console.log("iscomponent , >>>>>:",iscomponent);


  const toggleMenu = (): void => {
    setMenuToggle((Open) => !Open);
  };
  if (children) {
    
    return (
      <ListItem className="Mui-children" key={name} {...rest}>
        <Button
          className={clsx({ 'Mui-active': menuToggle })}
          startIcon={Icon && <Icon />}
          endIcon={
            menuToggle ? <ExpandLessTwoToneIcon /> : <ExpandMoreTwoToneIcon />
          }
          onClick={toggleMenu}
        >
          {t(name)}
        </Button>
        <Collapse in={menuToggle}>{children}</Collapse>
      </ListItem>
    );
  }
  return (
    <ListItem key={name} {...rest}>
     {(name == 'platformCircle')?
     <>
     {iscomponent&&
     <>
      <ExpolerMenuItem primaryCircle={true} userid={user.id} circleid={user?.clientPrimaryCircle?.id}   activeClassName="Mui-active" />
     </>
     
     }
     </> 
    :(name == 'Our Network')?
    <>
    {/* {iscomponent&&
    <>
     <ExpolerMenuItem primaryCircle={false} name={name} userid={user.id} circleid={user?.clientPrimaryCircle?.id} activeClassName="Mui-active" toggleSidebar={toggleSidebar} />
    </>
    
    } */}
    </>


     :
    <>{(name != 'My Circles') ?
    <Button
      activeClassName="Mui-active"
      component={RouterLink}
      exact
      onClick={toggleSidebar}
      to={link}
      startIcon={Icon && <Icon />}
    >
    {t(name)}
    {badge && <Badge badgeContent={badge} />}
  </Button>
  :
  <>
  {matchAccessType(user.accessType) &&
    <Button
    activeClassName="Mui-active"
    component={RouterLink}
    exact
    onClick={toggleSidebar}
    to={link}
    startIcon={Icon && <Icon />}
  >
    {t(name)}
    {badge && <Badge badgeContent={badge} />}
  </Button>
  }
  </>
    }
   
    </>
    }
    </ListItem>
  );
};

SidebarMenuItem.propTypes = {
  children: PropTypes.node,
  link: PropTypes.string,
  icon: PropTypes.elementType,
  badge: PropTypes.string,
  open: PropTypes.bool,
  name: PropTypes.string.isRequired
};

SidebarMenuItem.defaultProps = {
  open: false
};

export default SidebarMenuItem;
