



import React, {useState, useEffect} from 'react';
import { makeStyles, Theme, createStyles } from '@material-ui/core/styles';
import TreeView from '@material-ui/lab/TreeView';
import TreeItem, { TreeItemProps } from '@material-ui/lab/TreeItem';
import Typography from '@material-ui/core/Typography';
//import MailIcon from '@material-ui/icons/Mail';
//import DeleteIcon from '@material-ui/icons/Delete';
import Label from '@material-ui/icons/Label';
//import SupervisorAccountIcon from '@material-ui/icons/SupervisorAccount';
//import InfoIcon from '@material-ui/icons/Info';
//import ForumIcon from '@material-ui/icons/Forum';
//import LocalOfferIcon from '@material-ui/icons/LocalOffer';
//import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
//import ArrowRightIcon from '@material-ui/icons/ArrowRight';
import { SvgIconProps } from '@material-ui/core/SvgIcon';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import { callAPI,callAPIWithoutAuth } from 'src/utils/apiUtils';
//import { getUser } from 'src/helpers/auth';
import { NavLink as RouterLink, useParams } from 'react-router-dom';
import { prefixUrl,matchAccessType } from 'src/helpers/common';
import { Button, Badge, Collapse, ListItem } from '@material-ui/core';
import {useDispatch, useSelector} from 'react-redux';
import {getExploreCircleMenuItem, getExploreCircleMenuItemPublic} from 'src/slices/exploreMenuCircle';


declare module 'csstype' {
  interface Properties {
    '--tree-view-color'?: string;
    '--tree-view-bg-color'?: string;
  }
}

type StyledTreeItemProps = TreeItemProps & {
  bgColor?: string;
  color?: string;
  labelIcon: React.ElementType<SvgIconProps>;
  labelInfo?: string;
  labelText: string;
};

const useTreeItemStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      color: '#000000',//theme.palette.text.secondary,
      textDecoration : 'none' ,
      
      '&:hover > $content': {
        //backgroundColor: theme.palette.action.hover,
        color: '#1a75ff',//theme.palette.text.secondary,
        borderRadius:'6px',
        backgroundColor: '#F6F8FB',

      },
      // '&:$selected > $content $label':{
      //   //color: '#1a75ff',//theme.palette.text.secondary,
      //   borderRadius:'6px',
      //   backgroundColor: '#F6F8FB',
      //   color: '#1a75ff',
      // }
      '.MuiTreeItem-content.Mui-selected': {
        backgroundColor: 'transparent',
    },
      
      '&:focus > $content, &$selected > $content': {
        // backgroundColor: `var(--tree-view-bg-color, ${theme.palette.grey[400]})`,
        // color: 'var(--tree-view-color)',
        borderRadius:'6px',
        backgroundColor: '#F6F8FB',
        color: '#1a75ff',
      },
      '&:focus > $content $label, &label:hover > $content $label, &$selected > $content $label': {
        
        //borderRadius:0,
        color: '#1a75ff',//theme.palette.text.secondary,
        borderRadius:'6px',
        backgroundColor: '#F6F8FB',
     
      },
    },
    content: {
      color: '#3b4792' , //theme.palette.text.secondary,
      textDecoration : 'none' ,
      //borderTopRightRadius: theme.spacing(2),
      //borderBottomRightRadius: theme.spacing(2),
      paddingRight: theme.spacing(1),
      fontWeight: theme.typography.fontWeightMedium,
      '$expanded > &': {
        fontWeight: theme.typography.fontWeightRegular,
      },

    },
    group: {
      marginLeft: 15,
      fontWeight: 700 ,
      textDecoration:'none',
      '& $content': {
        //paddingLeft: theme.spacing(2),
        textDecoration:'none',
      },
    },
    expanded: {},
    selected: {},
    label: {
      fontWeight: 'inherit',
      color: 'inherit',
      
    },
    labelRoot: {
      display: 'flex',
      alignItems: 'center',
      padding: '0px',
      textDecorationLine:'none',
    },
    // labelIcon: {
    //   marginRight: theme.spacing(1),
    // },
    labelText: {
      fontWeight: 'inherit',
      flexGrow: 1,
      padding: '8.5px',
      textDecorationLine:'none',
      

    },
    
  }),
);

//onClick={()=>history.push(prefixUrl+'/circles/'+slugify(circleName)+'-'+id+'/'+slugify(value.name)+'-'+value.id)}

function StyledTreeItem(props) {
    const classes = useTreeItemStyles();
    const { labelText, labelIcon: LabelIcon, labelInfo, color, bgColor, Id, parentId, parentName, ischild, ftype,toggleSidebar, first, ...other } = props;
    // console.log("cicle_idcicle_idcicle_id> >>>>", ftype, ischild, parentName ,parentId, Id );
    


    const slugify = (text) =>{
      const from = "ãàáäâẽèéëêìíïîõòóöôùúüûñç·/_,:;"
      const to = "aaaaaeeeeeiiiiooooouuuunc------"
    
      const newText = text.split('').map(
        (letter, i) => letter.replace(new RegExp(from.charAt(i), 'g'), to.charAt(i)))
    
      return text
        .toString()                     // Cast to string
        .toLowerCase()                  // Convert the string to lowercase letters
        .trim()                         // Remove whitespace from both sides of a string
        .replace(/\s+/g, '-')           // Replace spaces with -
        .replace(/&/g, '-y-')           // Replace & with 'and'
        .replace(/[^\w\-]+/g, '')       // Remove all non-word chars
        .replace(/\-\-+/g, '-');        // Replace multiple - with single -
    } 

    // console.log("url >>>> ", Id ? `${prefixUrl}/circles/profile/${Id}` : `${prefixUrl}/circles/${ parentName}-${parentId}/${labelText}-${Id}`);

    const checkparentname= parentName && slugify(parentName);
    const checklabelText = labelText && slugify(labelText);

    // console.log(ischild === true ? `${prefixUrl}/circles/profile/${Id}` : `${prefixUrl}/circles/${checkparentname}-${parentId}/${checklabelText}-${Id}`);
    // console.log("checkparentname>>>", checkparentname,  " checklabelText >>> ", checklabelText);


    const getUrl=()=>{
      if (ischild){
        return Id ?  `${prefixUrl}/circles/profile/${Id}` : `${prefixUrl}/circles`;
      }
      else {
        if ( ischild ===false){
          return `${prefixUrl}/circles/profile/${Id}`;
        }
        else{
          return `${prefixUrl}/circles`;
        }
      }
    }
    const getUrlPublic=()=>{
      if (first){
        return '/';
      }
      else {
        return `/circle/${Id}`
      }
    }

    // console.log("getUrl:::", getUrl())



  return (
    <>
    {props.public ?
  <TreeItem
      label={
        <RouterLink  
        style={{ textDecoration: 'none'  }}
        className='mat-color-0'
        exact
        to={getUrlPublic()}>
        <div className={classes.labelRoot}>
          <span className="iconify list-icon" data-icon="mdi:hexagon-multiple-outline"></span>
            <Typography  className={classes.labelText} sx={{fontWeight:'700'}}>
                { labelText?.length > 25 ? `${ labelText.substring(0, 25)}...` : labelText}
            </Typography>
            <Typography  sx={{fontWeight:'700'}}>
                {labelInfo}
            </Typography>


        </div>
        </RouterLink>
      }
      style={{
        '--tree-view-color': color,
        '--tree-view-bg-color': bgColor,
      }}
      classes={{
        root: classes.root,
        content: classes.content,
        expanded: classes.expanded,
        selected: classes.selected,
        group: classes.group,
        label: classes.label,
      }}
      {...other}
    /> : <RouterLink  
      style={{ textDecoration: 'none'  }}
      className='mat-color-0'
      exact
      to={getUrl()}>
    <TreeItem
      label={
        
        <div className={classes.labelRoot}>
          <span className="iconify list-icon" data-icon="mdi:hexagon-multiple-outline"></span>
            <Typography  className={classes.labelText} sx={{fontWeight:'700'}}>
                { labelText?.length > 25 ? `${ labelText.substring(0, 25)}...` : labelText}
            </Typography>
            <Typography  sx={{fontWeight:'700'}}>
                {labelInfo}
            </Typography>


        </div>
      }
      style={{
        '--tree-view-color': color,
        '--tree-view-bg-color': bgColor,
      }}
      classes={{
        root: classes.root,
        content: classes.content,
        expanded: classes.expanded,
        selected: classes.selected,
        group: classes.group,
        label: classes.label,
      }}
      {...other}
    />
    </RouterLink> }
    </>
  );
}

const useStyles = makeStyles(
  createStyles({
    root: {
      height: 'auto',
      flexGrow: 1,
      maxWidth: 400,
      textDecorationLine:'none',
    },
  }),
);

export default function ExpolerMenuItem(props) {
    const classes = useStyles();
    const dispatch = useDispatch();
    const { menucircles } = useSelector((state)=> {return ({menucircles : state.menuCircle.menucircles})});
    const { menucirclesPublic } = useSelector((state)=> {return ({menucirclesPublic : state.menuCircle.menucirclesPublic})});

    const clientId = localStorage.getItem('clientId');
    const [circle, setCircle] = useState([]);
    const pimryCircle = props.primaryCircle; 
    const [ournetworks, setOurnetworks] = useState([]);
    const [secPltCircle, setSecPltCircle] = useState([]);
  
    const getUserCircles = async () => {
        if(props?.public){
          const apiResponse = await callAPI(`get-circle-with-child/${clientId || props?.id}`,{}, "GET");
          await dispatch(getExploreCircleMenuItemPublic(apiResponse?.data?.data));
        } else {
          const apiResponse = await callAPI(`get-auth-user-circle/${clientId}`,{}, "GET");
          await dispatch(getExploreCircleMenuItem(apiResponse?.data?.data));
        }
    }

    useEffect(() => {
        if(props?.public){
          // setCircle([menucirclesPublic['0']])
          // setOurnetworks([menucirclesPublic['0']]);
          if (Object.keys(menucirclesPublic).length !== 0){
            setCircle([menucirclesPublic?.circles[0]])
            setOurnetworks([menucirclesPublic?.circles[0]]);
            setSecPltCircle(menucirclesPublic?.secResult);
          }

          // setCircle([menucirclesPublic?.circles[0]])
          // setOurnetworks([menucirclesPublic?.circles[0]]);
        } else {
        setCircle(menucircles?.primary_circle)
        setOurnetworks(menucircles?.our_networks);
        setSecPltCircle(menucircles?.secResult);
        }
        //console.log(menucirclesPublic, menucircles, 'd')
    },[menucircles, menucirclesPublic])

    useEffect(() => {
        getUserCircles();
    }, [props?.id])



    // console.log("circle >>", circle);



    const TreeReturn = (datacircle, ftype ,parentId=null, parentName=null, first=false) => {

      // console.log("datacircle >>", datacircle);

        return (datacircle?.map((item, i) => {
          return (
            <> 
            {!props?.public?  <>
                { item?.child?.length==0?
                  (<StyledTreeItem ftype={ftype} public={props.public} ischild={true}  key={item?.circleId} nodeId={item?.circleId.toString()} labelText={item?.name} parentName={parentName} parentId={parentId} Id={item?.circleId} toggleSidebar={props.toggleSidebar} />)
                    : (<StyledTreeItem ftype={ftype}  public={props.public} ischild={false}  key={item?.circleId} nodeId={item?.circleId.toString()} labelText={item?.name}  parentName={parentName} parentId={parentId} Id={item?.circleId} toggleSidebar={props.toggleSidebar} >
                      {TreeReturn(item?.child, ftype , item?.circleId, item?.name)}
                  </StyledTreeItem>) 
                }
              </> : <>
                { item?.child?.length==0?
                  <>{item?.punlishStatus===1 ? <StyledTreeItem ftype={ftype} public={props.public} ischild={true} first={first} key={item?.circleId} nodeId={item?.circleId.toString()} labelText={item?.name} parentName={parentName} parentId={parentId} Id={item?.circleId} toggleSidebar={props.toggleSidebar} /> : ''}</>
                    : <>{item?.punlishStatus===1 ? <StyledTreeItem ftype={ftype}  public={props.public} ischild={false} first={first} key={item?.circleId} nodeId={item?.circleId.toString()} labelText={item?.name}  parentName={parentName} parentId={parentId} Id={item?.circleId} toggleSidebar={props.toggleSidebar} >
                      {item?.child.filter(ii => ii.punlishStatus ===1).length > 0 ? TreeReturn(item?.child, ftype , item?.circleId, item?.name) : ''}
                  </StyledTreeItem> : ''}</> 
                }
              </>}
            </>
          );
        }))
    }


    return (
      <>{circle?.length >0 && circle[0]?.circleId &&
        <TreeView
        className={`${classes.root} ${circle[0]?.circleId.toString()}`}
        defaultExpanded={[`${circle[0]?.circleId}`]}
        defaultCollapseIcon={<ExpandMoreIcon />}
        defaultExpandIcon={<ChevronRightIcon />}
        
        defaultEndIcon={<div style={{ width: 24 }} />}
        multiSelect={false}
        >
          {pimryCircle?
        <>
          {TreeReturn(circle, 1,null, null, true)}
          {TreeReturn(secPltCircle, 3,null, null, false)}
        </>
        : <>
          <StyledTreeItem public={props.public}  nodeId="1" labelText={props.name} labelIcon={Label} >
              {TreeReturn(ournetworks, 2)}
          </StyledTreeItem>
        </>
        }
        </TreeView>}
      </>
    );
}
