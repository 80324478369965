import { ReactNode } from 'react';
//import PostAddOutlined from '@material-ui/icons/PostAddOutlined';
import SupervisedUserCircleOutlined from '@material-ui/icons/SupervisedUserCircleOutlined';
//import PermIdentityOutlined from '@material-ui/icons/PermIdentityOutlined';
import AccountCircleTwoTone from '@material-ui/icons/AccountCircleTwoTone';
import PeopleAltIcon from '@material-ui/icons//PeopleAlt';
import ExploreIcon from '@material-ui/icons/Explore';
//import ExitToAppIcon from '@material-ui/icons/ExitToApp';
import PlayCircleFilledIcon from '@material-ui/icons/PlayCircleFilled';
//import ExpolerMenuItem from '../../../../components/ExpolerMenuItem/index';
// import PersonOutlineTwoToneIcon from '@material-ui/icons/PersonOutlineTwoTone';
import AccountCircleIcon from '@material-ui/icons/AccountCircle';
// import LoginIcon from '@material-ui/icons/Login';
import LogoutIcon from '@mui/icons-material/Logout';

export interface MenuItem {
  link?: string;
  icon?: ReactNode;
  badge?: string;
  items?: MenuItem[];
  role?: Number[];
  name: string;
  component?: ReactNode;
  iscomponent?: boolean;
}

export interface MenuItems {
  items: MenuItem[];
  heading: string;
}



const menuItems: MenuItems[] = [
  {
    heading: 'Member Dashboard',
    items: [
      {
        name: 'My Journey',
        //icon: SupervisedUserCircleOutlined,
        icon: PlayCircleFilledIcon,
        link: '/'
      },
      {
        name: 'Explore',
        icon: ExploreIcon,
        link: '/explorecontent'
      },
      {
        name: 'Connections',
        icon: SupervisedUserCircleOutlined,
        link: '/connections'
      },
      {
        name: 'My Account',
        icon: AccountCircleIcon,
        link: '/myaccount'
      },
      {
        name: 'Sign Out',
        icon: LogoutIcon,
        link: '#',
    
      },
    ]
  },
  {
    heading: 'CIRCLES',
    items: [
      {

        name: 'platformCircle',
        icon: AccountCircleTwoTone,
        link: '/circles',
        iscomponent: true,
      }, 
      {
        name: 'Our Network',
        icon: SupervisedUserCircleOutlined,
        link: '/circles',
        iscomponent: true,
      },
    ]
  },
  {
    heading: 'PLATFORM',
    items: [
      {
        name: 'User Admin',
        icon: PeopleAltIcon,
        role:[0,1],
        link: '/users'
      },
      // {
      //   name: 'My Account',
      //   icon: PermIdentityOutlined,
      //   link: '/myaccount'
      // },

    ],
  }  
];
export default menuItems;
