import { FC, ReactNode } from 'react';
import PropTypes from 'prop-types';
import { experimentalStyled } from '@material-ui/core/styles';
import { Box } from '@material-ui/core';

import Header from './Header';
import Footer from 'src/components/Footer';
import { getUser } from 'src/helpers/auth';
import SidebarPublic from './Sidebar/public';

interface SidebarLayoutProps {
  children?: ReactNode;
}

const MainWrapper = experimentalStyled(Box)(
  ({ theme }) => `
        flex: 1 1 auto;
        display: flex;
        height: 100%;
        @media (min-width: ${theme.breakpoints.values.lg}px) {
          padding-left: ${theme.sidebar.width};
      }
`
);

const MainContent = experimentalStyled(Box)(
  ({ theme }) => `
        margin-top: ${theme.header.height};
        flex: 1 1 auto;
        overflow: auto;
`
);

const PublicLayout: FC<SidebarLayoutProps> = ({ children }) => {

  return (
    <>
    
    <SidebarPublic/>
      <MainWrapper>
        <Header publicPortal={true}/>
        <MainContent>{children}
        </MainContent>
      </MainWrapper>
    </>
  );
};

PublicLayout.propTypes = {
  children: PropTypes.node
};

export default PublicLayout;
