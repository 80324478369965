import { createSlice } from '@reduxjs/toolkit';
import type { PayloadAction } from '@reduxjs/toolkit';
import _ from 'lodash';
import type { AppThunk } from 'src/store'

interface Profile {
    id: number;
    firstName:string;
    lastName:string;
    date:Date;
    image:string;
    email:string;
    password:string;
    biography:string;
    facebook:string;
    twitter:string;
    instagram:string;
    linkedin:string;
    webpage:string;
    photo:string;
    header_photo:string;
    video:string;
  }

interface MyAccountState {
    userprofile: Profile,
}

const initialState = {
  userprofile: {}
};

const slice = createSlice({
  name: 'myaccount',
  initialState,
  reducers: {
    createProfile(state: MyAccountState, action: PayloadAction<{ userprofile: Profile; }>) {
      const { userprofile } = action.payload;
      //console.log('reducer',action.payload)
      state.userprofile = {...state.userprofile, ...userprofile};
    } 
  }
});

export const reducer = slice.reducer;

export const getProfile = (data: any): AppThunk => async (dispatch) => {
    dispatch(slice.actions.createProfile({userprofile:data}));
  };

export default slice;
