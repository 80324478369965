import { useContext, useEffect, useState } from 'react';
import { Scrollbars } from 'react-custom-scrollbars-2';
import { SidebarContext } from 'src/contexts/SidebarContext';
import MenuIcon from '@material-ui/icons/Menu';
import LaunchOutlinedIcon from '@material-ui/icons/LaunchOutlined';
import ArrowLeftOutlinedIcon from '@material-ui/icons/ArrowLeftOutlined';
import CancelIcon from '@material-ui/icons/Cancel';
import { Box, Button, Drawer, Hidden, Typography } from '@material-ui/core';
import { getUser, setClientId } from 'src/helpers/auth';
import { SidebarDivider, SidebarWrapper, TopSection, TopSectionPubic, useImgStyle } from '.';
import ExpolerMenuItem from 'src/components/ExpolerMenuItem';
import { callAPI } from 'src/utils/apiUtils';
import { apiUrls } from 'src/utils/apiUrls';
import { defaultConfig } from 'src/config';
import { useDispatch, useSelector } from 'react-redux';
import { getExploreCircleMenuItemPublic } from 'src/slices/exploreMenuCircle';





function SidebarPublic() {
  const dispatch = useDispatch();
  const tgl= localStorage.getItem("sidebarStrict")==='1'? false : true
  const [toggle, setToggle] = useState(tgl)
  const [fixToggle, setFixToggle] = useState(tgl)
  const [circle, setCircle] = useState([])
  const { sidebarToggle, toggleSidebar } = useContext(SidebarContext);
  
  const { menucirclesPublic } = useSelector((state)=> state.menuCircle);


  useEffect(() => {
    // console.log("menucirclesPublic >Sidebar layout > public folder >>", menucirclesPublic)
    if (Object.keys(menucirclesPublic).length !== 0){
      setCircle([menucirclesPublic?.circles[0]])
    }
},[menucirclesPublic])

  const closeSidebar = () => toggleSidebar();
  const classes = useImgStyle();
 const user = getUser ();
 const [logo, setLogoImage] = useState('')

 const [id, setId] = useState(null)

 const setSidebar = () => {
  setFixToggle(!fixToggle);
  localStorage.setItem("sidebarStrict", "1");
 }
 const remSidebar = () => {
  setFixToggle(!fixToggle);
  localStorage.removeItem("sidebarStrict");
 }


 const getClientIdByHostName = async () =>{
  const urlParams = new URLSearchParams(window.location.search);
  const myParam = urlParams.get('clientName');
 // console.log(window.location.hostname)
  let urlFostName = window.location.hostname == 'localhost' ? defaultConfig.hostName : window.location.hostname;
  let bodyContent = { urlHostName :  urlFostName ,clientName:(myParam != null ? myParam: '') };

  const response = await callAPI(apiUrls.getClientId,{},'POST',bodyContent);
  if(response.data != undefined && Object.keys(response.data.data).length > 0){
    let ids = (response.data.data.clienteId) ? response.data.data.clienteId : defaultConfig.clientId;
    let clientPlatformId = (response.data.data.clientPlatformId) ? response.data.data.clientPlatformId : 0;
    if(response.data?.data?.logoFinal){
      setLogoImage(response.data?.data?.logoFinal);
    }
    setId(ids)
    setClientId(ids,clientPlatformId);
  }
}

const breadCrumActive =  (circles, arr) => {
  circles.forEach(item => {
    if(item?.punlishStatus == 1){
      if(item?.child.length>0){
        arr.push('1');
        breadCrumActive(item?.child, arr);
      }
      return arr
    } else {      
      if(item?.child.length>0){
        breadCrumActive(item?.child, arr);
      }
      return arr
    }
  })
  return arr
}

useEffect(() => {
  getClientIdByHostName()
},[])


const getUserCircles = async() => {
  const clientId = localStorage.getItem('clientId');
  const apiResponse = await callAPI(`get-circle-with-child/${clientId || id}`,{}, "GET");
  await dispatch(getExploreCircleMenuItemPublic(apiResponse?.data?.data));
}

useEffect(() => {
  getUserCircles();
}, [id])

  return (
    <>
      <Hidden lgDown>
      {/* <Typography>2 for desktop </Typography> */}
        <SidebarWrapper className={`${toggle ? 'open': ''} ${!fixToggle === true ? 'strictSidebar' : ''}`} >
          <Scrollbars autoHide>
            { circle.length >0 && breadCrumActive(circle, []).length>1 &&
            <Box component={'div'} className='conditional_bar'>
              <Button onClick={() => {setSidebar(); setToggle(!toggle)}}>
                <MenuIcon />
              </Button>
            </Box>
            }
            <TopSectionPubic>
                  <img alt='' src={logo} className={classes.root} />
            </TopSectionPubic>
            <SidebarDivider sx={{ my: 2, mx: 2 }} />
            <Box component={'div'} className="new_sidebar_cta">
                {/* <Button className='full' onClick={() => setSidebar()}><LaunchOutlinedIcon /></Button> */}
                <Button className='exit' onClick={() =>{ setToggle(!toggle); remSidebar()}}><ArrowLeftOutlinedIcon /></Button>
            </Box>

            <ExpolerMenuItem primaryCircle={true} userid={null} circleid={null} id={id}  activeClassName="Mui-active" public={true}/>
          </Scrollbars>
        </SidebarWrapper>
      </Hidden>
      <Hidden smUp>
          {/* <Typography>3 for small display </Typography> */}

        <Drawer
          anchor="left"
          open={sidebarToggle}
          onClose={closeSidebar}
          variant="temporary"
          elevation={9}
        >
          <SidebarWrapper  className={`${toggle ? 'open': ''}`}>
            <Scrollbars autoHide>
              <TopSection>
                  <img alt='' src={logo} className={classes.root} />
              </TopSection>
              <SidebarDivider sx={{ my: 2, mx: 2 }} />
             
            <ExpolerMenuItem primaryCircle={true} userid={null} circleid={null} id={id}  activeClassName="Mui-active" public={true}/>
            </Scrollbars>
          </SidebarWrapper>
        </Drawer>
      </Hidden>
    </>
  );
}

export default SidebarPublic;
