import { List, Avatar } from '@material-ui/core';
//import { useTranslation } from 'react-i18next';
import { prefixUrl } from 'src/helpers/common';
import { getUser } from 'src/helpers/auth';
import {  useState } from 'react';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import ArrowDropUpIcon from '@material-ui/icons/ArrowDropUp';
import {  useHistory } from "react-router-dom";
import { useEffect } from 'react';
import useAuth from 'src/hooks/useAuth';
import {
  Collapse,
  ListItemIcon,
  ListItem,
  ListItemText,
  
} from '@material-ui/core';
import './usermenus.css';

const UserMenus = ({ menuItems, handleUserMenu }) => {
    //const { t }: { t: any } = useTranslation();
    const {user} = useAuth();
    const [open, setOpen] = useState(true);
    const [activeClass, setActiveClass] = useState('');
    const history = useHistory()
    const {  logout } = useAuth();
  
    const handleClick = () => {
      setOpen(!open);
      handleUserMenu(!open);
    };
  
    useEffect(() => { 
      const getPath = path.split('/')[2]
      const elem = menuItems[0]['items']?.find((ele)=>ele?.link === ('/'+getPath));
      if(elem){
        listItemRedirection(`/${getPath}`)
      }
    }, [])
    
  
    const handleLogout = async (): Promise<void> => {
      try {
        // handleClose();
        await logout();
  
        history.push(`${prefixUrl}/`);
      } catch (err) {
        console.error(err);
      }
    };
  
    const listItemRedirection = (path) => {
      history.push('/dashboard' + path);
      setActiveClass(path)
    
      if (path === '#') {
        handleLogout()
      }
    };
  
    const path = window.location.pathname
   
  
    useEffect(() => {
  
      if (path.match(/[0-9]/)?.length > 0) {
  
        setOpen(false)
  
      }
    }, [path])
  
   // const myJurneyClass = path.match(/^\/myprofile/) ? "active" : "";
  
  
    const insertIcon = (icon) => {
      switch (typeof icon) {
        case 'object':
          const iconType = typeof icon?.type;
          if (
            iconType === 'function'
            || (iconType === 'object' && typeof icon.type?.render ===
              'function')) {
            const Icon = icon
            return <Icon />;
          }
          if (icon.props) {
            return icon;
          }
          return <>{icon}</>;
        case 'function':
          return icon()
        case 'string':
          if (icon.indexOf('svg') > -1) {
            return icon;
          }
       
        default:
          return <span>Iconless</span>;
      }
    }
  
  
    return (<>
  
  
    
      <div className="user_nav">
        <List
          sx={{ width: '100%', maxWidth: 360, bgcolor: 'background.paper' }}
          component="nav"
          aria-labelledby="nested-list-subheader"
        >
          <ListItem className='active'>
  
            <div className={`user_name_option cursorpointer  ${('/' + path.split('/').reverse()[0] === '/myprofile') ? 'active' : " "}`} onClick={() => { listItemRedirection('/myprofile') }}>
  
              <ListItemIcon>
                <Avatar variant="rounded" alt="Remy Sharp" src={user?.image} />
              </ListItemIcon>
  
              <ListItemText primary={user?.name?.toUpperCase()} />
  
              <span onClick={handleClick}>{open ? <ArrowDropDownIcon /> : <ArrowDropUpIcon />} </span>
  
            </div>
          </ListItem>
          <Collapse className='user_options' in={open} timeout="auto" unmountOnExit>
  
            {menuItems.map((section) => {
              return (<>
                {section.heading === 'Member Dashboard' && section.items.map((menu, i) => {
  
                  let icon = menu.icon;
                  return (<ListItem sx={{ pl: 4 }}>
  
                    <div className={`user_options_menu  ${(activeClass === menu.link) ? 'active' : ' '}`} >
                      <ListItemIcon>
  
                        {/* <icon /> */}
                        {insertIcon(icon)}
  
                      </ListItemIcon>
                      <ListItemText className='cursorpointer ' primary={menu?.name} onClick={() => { listItemRedirection(menu?.link) }} />
                    </div>
                  </ListItem>);
                })}
              </>)
  
            })
            }
          </Collapse>
  
        </List>
      </div>
    </>)
  }


export default UserMenus